<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light topbar fixed-top"> -->
<nav class="navbar navbar-expand-lg topbar navbar-light fixed-top" id="topbar"
  (window:resize)="onResize($event)" [class.topbar-transparent]="scrollTopTrans" >
  <a class="navbar-brand" routerLink="/">
    <img class="responsive" src="assets/images/DRINKART_NGO.png" width="90" height="75" alt="">
  </a>
  <div class="mr-auto">

  </div>
  <h5 class="navbar-nav ml-auto ml-md-0 mr-4 mt-2 topbutton_sm " *ngIf="currentUser">
    <label (click)="openNav()"><i class="fas fa-shopping-cart"></i></label>
  </h5>
  <h5 class="navbar-nav ml-auto ml-md-0 mr-4 mt-2 topbutton_sm " *ngIf="!currentUser">
    <label [routerLink]="['/login']"><i class="fas fa-cart-plus"></i></label>
  </h5>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>




  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <div class="mr-auto">

    </div>

    <ng-template #popContentCategorias>

      <div style="height: 250px; overflow-y: scroll;">
        <div class="d-flex flex-column justify-content-center" *ngFor="let cat of allCategorias">

          <div [routerLink]="[ '/portafolio']" [queryParams]="{id: cat.id}" style="color: black; text-align: center; outline:none;">
            <label>
              <i style="width: 30px; font-size: x-large; color: #37da64;" class="{{cat.icon}}"></i>
              <br>
              {{cat.descripcion}}</label> 
            </div>
        </div>
      </div>
    </ng-template>





    <ng-template #popTitleCategorias><b style="color: #9a9a9a; text-align: center;">Categorías</b></ng-template>
    <h5 class="nav-link topbutton_md mt-2" (click)="categorias()" placement="bottom" [ngbPopover]="popContentCategorias"
      [popoverTitle]="popTitleCategorias">
      <label>DA! Home</label>
    </h5>
    <h5 class="nav-link topbutton_md mt-2" [routerLink]="['/eventos']" style="outline:none;">
      <label> DA! Sessions </label>
    </h5>
    <h5 class="nav-link topbutton_md mt-2" [routerLink]="['/login']" *ngIf="!currentUser">
      <label>Ingresar</label>
    </h5>
    <!-- <h5 class="nav-link topbutton_md mt-2" [routerLink]="['/email-por-confirmar']" *ngIf="!currentUser">
      <label>Crea una cuenta</label>
    </h5> -->

    <ng-template #popContentUsuario>
        <a class="mt-2" style="color: #9a9a9a; " [routerLink]="['/mis-compras']">
          <i style="width: 40px; text-align: center; font-size: x-large; color: #37da64; " class="fa fa-shopping-basket"> </i> <label
            style="margin-left: 3px; font-size: large;"> Mis compras </label>
        </a><br>
        <a class="mt-2" style="color: #9a9a9a;" [routerLink]="['/mis-eventos']">
          <i style="width: 40px; text-align: center; font-size: x-large; color: #37da64;" class="fa fa-calendar-week"> </i> 
          <label style="margin-left: 3px; font-size: large">
            Mis reservaciones </label>
        </a><br>
        <a class="mt-2" style="color: #9a9a9a;" (click)="logout()">
          <i style="width: 40px; text-align: center; font-size: x-large; color: #37da64;" class="fa fa-sign-out-alt"> </i> 
          <label style="margin-left: 3px; font-size: large">
            Salir</label> </a>
    </ng-template>
    <ng-template #popTitleUsuario><b class="mr-1" style="color: grey;"> {{currentUser.email}} </b></ng-template>
    <h5 class="nav-link topbutton_md mt-2 " *ngIf="currentUser" [ngbPopover]="popContentUsuario"
      [popoverTitle]="popTitleUsuario">
      <label><i class="fas fa-user-cog"></i></label>
    </h5>


    <h5 class="nav-link topbutton_md mt-2" *ngIf="currentUser" (click)="openNav()">
      <label><i class="fas fa-shopping-cart" id="openNavButton"></i></label>
    </h5>

    <h5 class="nav-link topbutton_md mt-2" *ngIf="!currentUser" [routerLink]="[ '/login']">
      <label><i class="fas fa-cart-plus"></i></label>
    </h5>

    <!-- Opciones para tamaño small -->
    <div class="dropdown-divider" *ngIf="currentUser"></div>
    <h5 *ngIf="currentUser" class="nav-link topbutton_sm mt-2"><label>{{currentUser.email}} </label></h5>
    <div class="dropdown-divider"></div>
    <h5 class="nav-link topbutton_sm mt-2" [routerLink]="['/login']" *ngIf="!currentUser" data-toggle="collapse"
      data-target="#navbarSupportedContent">
      <label><i class="fas fa-user-tie" style="width: 30px;"></i>Ingresar</label>
    </h5>
    <h5 class="nav-link topbutton_sm mt-2" [routerLink]="['/email-por-confirmar']" *ngIf="!currentUser"
      data-toggle="collapse" data-target="#navbarSupportedContent">
       <label> <i class="fas fa-user-plus" style="width: 30px;"></i>Crea una cuenta</label>
    </h5>
    <h5 class="nav-link topbutton_sm mt-2" *ngIf="currentUser" (click)="logout()" data-toggle="collapse"
      data-target="#navbarSupportedContent">
      <label> <i class="fas fa-sign-out-alt" style="width: 30px;"></i> Salir</label>
    </h5>
    <h5 class="nav-link topbutton_sm mt-2" *ngIf="currentUser" [routerLink]="['/mis-eventos']" data-toggle="collapse"
      data-target="#navbarSupportedContent">
      <label> <i class="fas fa-calendar-week" style="width: 30px;"></i> Mis reservaciones</label>
    </h5>
    <h5 class="nav-link topbutton_sm mt-2" *ngIf="currentUser" [routerLink]="['/mis-compras']" data-toggle="collapse"
      data-target="#navbarSupportedContent">
      <label> <i class="fas fa-shopping-basket" style="width: 30px;"></i> Mis compras</label>
    </h5>
    <div class="dropdown-divider"></div>


    <ng-template #popContentCategoriasMovil>
      <div class="row" style=" height: 100px; overflow-y: scroll;">
        <div class="col-sm-12 mr-2"  *ngFor="let cat of allCategorias">
          <a [routerLink]="[ '/portafolio']" [queryParams]="{id: cat.id}" style="color: black; "
            data-toggle="collapse" data-target="#navbarSupportedContent">
            <div class="d-flex align-items-center mb-1">
              <i style="width: 30px; text-align: center; font-size: x-large; color:#37dd64; margin-right: 5px; margin-left: 5px;" class="{{cat.icon}}"></i> 
              <label style="color: #b9b9b9; margin-top: 3px;"> {{cat.descripcion}}</label>
            </div>
          </a>
        </div>
      </div>

    </ng-template>
    <ng-template #popTitleCategoriasMovil><b>Categorias</b></ng-template>
    <h5 class="nav-link topbutton_sm mt-2" (click)="categorias()" placement="top"   [ngbPopover]="popContentCategoriasMovil"
      [popoverTitle]="popTitleCategoriasMovil">
      <label> <i class="fas fa-paint-brush" style="width: 30px;"></i> Drink Art Home</label>
    </h5>

    <h5 class="nav-link topbutton_sm mt-2" [routerLink]="['/eventos']" data-toggle="collapse"
      data-target="#navbarSupportedContent">
      <label> <i class="fas fa-calendar-check" style="width: 30px;"></i> Drink Art Sessions</label>
    </h5>

  </div>
</nav>


<div id="sideBarCart" (clickOutside)="CloseClickOutside()"
  class="w3-sidebar w3-card-4 w3-animate-left cd-cart overflow-auto" >
  <a class="closebtn" (click)="closeNav()"><label style="color: #ea5b34;">&times;</label></a>
  <h2>Carrito</h2>
  <div>
    <ul class="cd-cart-items">
      <li *ngFor="let prC of productosCarrito">
        <img class="img-fluid mr-2" style="width: 50px; height: 50px;" [src]="DJANGO_SERVER+prC.producto.imagen_mini"
          alt="" /> <span class="cd-qty">{{ prC.cantidad }}x </span> <span style="color: black;">{{prC.producto.nombre}}</span>
        <div class="cd-price">
          ${{prC.producto.precio}}
          <button class="btn ink-reaction btn-xs btn-light" (click)="decrementarItemCart(prC)"><span style="font-weight: 800; color: #ea5b34;">-</span></button>
          <input class="form-control-cantidad" style="width: 40px; color: #9a9a9a; font-weight: 600; font-size: large;" type='text' value="{{prC.cantidad}}">
          <button class="btn ink-reaction btn-xs btn-light" (click)="aumentarItemCart(prC)"><span style="font-weight: 800; color: #ea5b34;">+</span></button>
        </div>
        <div class="cd-item-remove" (click)="removeItemCart(prC)"><i class="fa fa-trash" aria-hidden="true"></i></div>
      </li>
    </ul>
  </div>

  <div *ngIf='total>0'>
    <div class="cd-cart-total">
      <p>Total <span>${{total}}</span></p>
      <p style="text-align: center; color: coral; font-size: small;">
        Los gastos del envio dependen de tu ubicación. <br>
        Si compras 4 o más el envio es gratis.</p>
      <button class="checkout-btn" (click)="getActivity(seeModal)" role="button">FINALIZAR PEDIDO</button>
      <!--<button class="checkout-btn text-uppercase" (click)="getActivity(seeModal)">Finalizar pedido</button>-->
      <!-- <button class="log-btn-modal text-center ml-5 mt-4" (click)="getActivity(seeModal)">Finalizar pedido</button> -->
      <div style="height: 200px;">

      </div>
    </div>

  </div>

  <div *ngIf='total===0'>
    <p><span>Sin productos agregados</span></p>
  </div>
</div>

<ng-template #seeModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="seeModal">Ticket de venta</h5>
    <a class="modal-title" (click)="closeModal(seeModal)"><label style="color: #ea5b34;">&times;</label></a>
  </div>
  <div class="modal-body">
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="static-1">
      <ngb-panel id="static-1" title="Ver detalle">
        <ng-template ngbPanelContent>
          <div *ngFor="let prC of productosCarrito">
            <img class="img-fluid mr-2" style="width: 50px; height: 50px;"
              [src]="DJANGO_SERVER+prC.producto.imagen_mini" alt="" />{{ prC.producto.nombre }}:
            <strong><label>${{ prC.producto.precio }}</label></strong>. Cantidad:
            <strong>{{ prC.cantidad }}</strong><br />
            <hr>
          </div>
          <div style="right: 10px;">
            <h3 style="color: #9a9a9a;">Total: ${{total}} <strong><label></label></strong><br /></h3>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-2" title="Datos del envío">
        <ng-template ngbPanelContent>
          <form [formGroup]="direccionForm" (ngSubmit)="validateForm()" (change)="cambioDomicilio()">
            Email: <strong><label>{{ currentUser.email }}</label></strong><br />
            <div class="row">
              <div class="col-md-12 input-group mb-3">
                <input class="form-control form-control-cantidad" type='number' formControlName="codigo"
                  maxlength="5" placeholder="Código postal"
                  aria-label="Ingresa tu código postal para envio" aria-describedby="basic-addon2" style="border-color: #ea5b34;"/>
                <div class="input-group-append">
                  <button type="button" class="btn btn-primary" (click)="getDireccion()">Validar CP</button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" *ngIf="codigoNovalido" style="color: red;">¡El código postal no es valido!</div>
            </div>
            <div class="row" *ngIf='muestraCombo'>
              <div class="col-md-12">
                <select class="form-control-cantidad" formControlName="asentamiento" (change)="pintaDomicilio()"
                  style="width: 100%; border-color: #ea5b34;" [ngClass]="{ 'is-invalid': submitted && f.asentamiento.errors }">
                  <option selected [value]="">Seleccione un asentamiento</option>
                  <option *ngFor="let estado of estados" [ngValue]="estado">
                    {{ estado.asentamiento }}
                  </option>
                </select>
                <div *ngIf="submitted && f.asentamiento.errors" class="invalid-feedback">
                  <div *ngIf="f.asentamiento.errors.required">Por favor, ingresa un asentamiento</div>
                </div>
              </div>
            </div>
            <div *ngIf='muestraInputs'>
              <div class="row mt-3">
                <div class="col-md-8">
                  <input formControlName="calle" class="form-control-calle" type='text' placeholder="Calle"  maxlength="50"
                    style="width: 100%; border-color: #ea5b34;" [ngClass]="{ 'is-invalid': submitted && f.calle.errors }" />
                    <div *ngIf="submitted && f.calle.errors" class="invalid-feedback">
                      <div *ngIf="f.calle.errors.required">Por favor, ingresa una calle</div>
                    </div>
                </div>
                <div class="col-md-4">
                  <input formControlName="numero" class="form-control-numero" type='text' placeholder="Número" maxlength="5"
                    style="width: 100%; border-color: #ea5b34;" [ngClass]="{ 'is-invalid': submitted && f.numero.errors }" />
                    <div *ngIf="submitted && f.numero.errors" class="invalid-feedback">
                      <div *ngIf="f.numero.errors.required">Por favor, ingresa un número exterior</div>
                    </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <input formControlName="nombre_receptor" class="form-control-nombre-receptor" type='text'  maxlength="149"
                    placeholder="Nombre de quien recibe" style="width: 100%; border-color: #ea5b34;" [ngClass]="{ 'is-invalid': submitted && f.numero.errors }">
                    <div *ngIf="submitted && f.nombre_receptor.errors" class="invalid-feedback">
                      <div *ngIf="f.nombre_receptor.errors.required">Por favor, ingresa el nombre de quien recibe el kit de arte.</div>
                    </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <textarea formControlName="referencia" class="form-control-referencia" type='text'  maxlength="250"
                    placeholder="Referencia" style="width: 100%; border-color: #ea5b34;"></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <hr>
                  Costo del envio: ${{gastoDeEnvio}}
                  <hr>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-lg btn-block" [ngClass]="{'btn-success': !this.metodoPago, 'btn-primary':this.metodoPago  }" (click)="acc.toggle('static-3')">Continuar con método de pago</button>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-3" title="Metodo de pago" [disabled]= "metodoPago" [ngbCollapse]="metodoPago">
        <ng-template ngbPanelContent>
          <div class="card-ingresar-body" style="width: 80%; margin-left: 10%; margin-right: 10%; text-align: center;">
            <button class="btn btn-primary btn-lg btn-block mt-1" (click)="pagar()">MERCADO PAGO</button>
            <button class="btn btn-lg btn-block mt-3 mb-3" (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed" aria-controls="collapseInformacionPago">OTRO MEDIO</button>
          </div>
          <div id="collapseInformacionPago" [ngbCollapse]="isCollapsed">
            <div class="card">
              <div class="card-body" style="text-align: center; width: 80%; margin-left: 10%;">
                <h6 style="color: #9a9a9a;">Presiona el siguiente botón para descargar las instrucciones de pago.</h6>
                <button type="submit" class="btn btn-primary btn-lg btn-block mt-3" (click)="finalizarVenta()">
                  DESCARGAR </button>
              </div>
              <div *ngIf="message" class="alert alert-success mt-2" role="alert">
                <div class="row">
                  <div class="col-sm-10">
                    <strong>Listo! </strong>{{message}}
                  </div>
                  <div class="col-sm-2">
                    <button type="button" class="close" (click)="cleanError()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer" *ngIf='muestraInputs'>
            <button type="button" class="checkout-btn-modal text-uppercase"
              (click)="finalizarVenta()">Finalizar</button>
          </div> -->
        </ng-template>
      </ngb-panel>
    </ngb-accordion>

    <!-- <div class="row">
      <div class="col-lg-12">
        <div *ngIf="submitted && direccionForm.controls.invalid" class="invalid-feedback">
          <div>Por favor, ingresa los datos de envío</div>
        </div> -->
        <!-- <div *ngIf="message" class="alert alert-success" role="alert">
          <strong>Success!</strong> {{message}}
          <button type="button" class="close" (click)="cleanError()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div> -->
      <!-- </div>
    </div> -->
  </div>
</ng-template>
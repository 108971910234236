<div class="col-md-6 offset-md-3">
  <div class="text-center text-muted" style="margin-top: 110px;">
    <h2 class="titulo-plumon animate__animated animate__backInDown">Sesiones anteriores</h2>
    <h3 class="section-subheading text-muted mb-5">
     ¡Estamos ansiosos por regresar!
    </h3>
  </div>
  <div id="myCarousel" class="carousel slide" data-ride="carousel" >
 
<div
  style=" position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-image: url('assets/images/marco.png'); background-size: cover; z-index: 1; ">
</div>

    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
      <li data-target="#myCarousel" data-slide-to="3"></li>
      <li data-target="#myCarousel" data-slide-to="4"></li>
      <li data-target="#myCarousel" data-slide-to="5"></li>
      <li data-target="#myCarousel" data-slide-to="6"></li>
      <li data-target="#myCarousel" data-slide-to="7"></li>
      <li data-target="#myCarousel" data-slide-to="8"></li>
      <li data-target="#myCarousel" data-slide-to="9"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="img-carousel-lg" src="assets/img/SESIONES_01.jpg" alt="First slide">
        <img class="img-carousel-sm" src="assets/img/SESIONES_01.jpg" alt="First slide">
      </div>
      <div class="carousel-item">
        <img class="img-carousel-lg"  src="assets/img/SESIONES_02.jpg" alt="Second slide">
        <img class="img-carousel-sm"  src="assets/img/SESIONES_02.jpg" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="img-carousel-lg" src="assets/img/SESIONES_03.jpg" alt="Third slide">
        <img class="img-carousel-sm" src="assets/img/SESIONES_03.jpg" alt="Third slide">
      </div>
      <div class="carousel-item">
        <img class="img-carousel-lg"  src="assets/img/SESIONES_04.jpg" alt="Second slide">
        <img class="img-carousel-sm"  src="assets/img/SESIONES_04.jpg" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="img-carousel-lg"  src="assets/img/SESIONES_05.jpg" alt="Second slide">
        <img class="img-carousel-sm"  src="assets/img/SESIONES_05.jpg" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="img-carousel-lg" src="assets/img/SESIONES_06.jpg" alt="Third slide">
        <img class="img-carousel-sm" src="assets/img/SESIONES_06.jpg" alt="Third slide">
      </div>
      <div class="carousel-item">
        <img class="img-carousel-lg"  src="assets/img/SESIONES_07.jpg" alt="Second slide">
        <img class="img-carousel-sm"  src="assets/img/SESIONES_07.jpg" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="img-carousel-lg" src="assets/img/SESIONES_08.jpg" alt="Third slide">
        <img class="img-carousel-sm" src="assets/img/SESIONES_08.jpg" alt="Third slide">
      </div>
      <div class="carousel-item">
        <img class="img-carousel-lg" src="assets/img/SESIONES_09.jpg" alt="Third slide">
        <img class="img-carousel-sm" src="assets/img/SESIONES_09.jpg" alt="Third slide">
      </div>
      <div class="carousel-item">
        <img class="img-carousel-lg" src="assets/img/SESIONES_10.jpg" alt="Third slide">
        <img class="img-carousel-sm" src="assets/img/SESIONES_10.jpg" alt="Third slide">
      </div>

      <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
        <span class="fa fa-chevron-left fa-4x" style="color:black; background-color: white; border-radius: 2px;"
          aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
        <span class="fa fa-chevron-right fa-4x" style="color:black; background-color: white; border-radius: 2px;"
          aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>


<!-- Sessions Grid-->
<section class="page-section" id="eventos">
  <div class="container">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">Próximas sesiones</h2>
      <h3 class="section-subheading text-muted mb-5">
        Estamos muy emocionados de que vivas esta increíble experiencia. <br>
        El spot es sorpresa, espera nuestro mensaje con la dirección.

        ¡Confía en ti, eres un artista!
      </h3>
    </div>

    <div
      style="position: absolute; top: 150px; left: 0px; width: 5rem;  height: 20rem; border-style: none; border-width: 2px; z-index: -100;">
      <img src="assets\images\mancha_03.png" style="width: 100%; height: 100%;" alt="">
    </div>
    <div
      style="position: absolute; bottom:20px; right: 0px; width: 5rem;  height: 20rem; border-style: none; border-width: 2px; z-index: -100;">
      <img src="assets\images\mancha_02.png" style="width: 100%; height: 100%;" alt="">
    </div>

    <div class="row evento-item mb-5 ml-2 mr-2" *ngFor="let l of list">




      <div class="container  p-0" style="color: #ff807e; background-color: white;">

        <div class="row no-gutters">


          <div class="col-lg-4 offset-lg-1 order-lg-1 showcase-text d-flex justify-content-center align-items-center"
            style="width: 100%; height: auto; border: #ff807e solid 2px; background-color: whitesmoke;">
            <div>
              <img [src]="DJANGO_SERVER+l.imagen_desc" alt="" style="width: 100%; height: auto;">
            </div>
          </div>


          <div class="col-lg-4 order-lg-2 showcase-text d-flex justify-content-center align-items-center"
            style="background-color: white; border: 2px; border-color: #ff807e; border-style: solid;">
            <div class=" d-flex justify-content-start align-items-start col-lg-12 mb-4">
              <div class="portfolio-caption ml-2">
                <div class="portfolio-caption-heading">
                  <h4 style="color: #4133e7; font-weight: 700;">{{l.nombre}}</h4>
                </div>
                <div class="portfolio-caption-subheading text-muted">
                  {{l.descripcion}}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-2 order-lg-3 showcase-text"
            style="background-color: white; border: 2px; border-color: #ff807e; border-style: solid;">


            <div class="m-0 p-0 d-flex col-lg-12 d-flex justify-content-center align-items-center mb-auto"
              style="background-color: #ff807e;">
              <div class="text-center" style="height: 120px; width:100%; color: white;">
                <h5>
                  {{l.fecha_evento | date:'MMM'}}</h5>
                <h1 class="mt-auto" style="font-weight: bold; font-size: 3rem;">{{l.fecha_evento | date:'d'}}
                </h1>
                <!-- <h6 class="mt-auto text-center" style="color: rgb(189, 189, 189);">{{l.fecha_evento | date:'yyy'}}  </h6>-->
                <h6>{{l.fecha_evento | date:'shortTime'}}
                </h6>
              </div>
            </div>

            <div class="col-lg-12 mt-auto">
              <div style="text-align: center;">
                <div class="mt-5">
                  <h6 class="text-muted">Lugares por reservar </h6>
                  <div class="d-flex justify-content-center align-items-center">
                    <button class="btn ink-reaction btn-xs btn-light" style="width: 40px;"
                      (click)="decrementarLugar(l.id)">-</button>
                    <input class="form-control-cantidad" style="width: 40px; text-align: center;" type='text'
                      [value]="getCantidad(l.id)" disabled>
                    <button class="btn ink-reaction btn-xs btn-light" style="width: 40px;"
                      (click)="aumentarLugar(l.id)">+</button>
                  </div>
                  <h5 class="mt-2 text-muted" style="text-align: center;">Total: ${{l.precio*getCantidad(l.id)}}</h5>
                  <button class="btn btn-primary btn-lg btn-block mt-4 mb-4" [disabled]="isDateAfter(l.fecha_evento)"
                    (click)='ReservarEvento(l, IniciarSesion, Pagar, AlertaCovid)'>
                    Reservar
                  </button>                 
                </div>

              </div>

            </div>

          </div>

        </div>







      </div>

    </div>
  </div>
</section>


<ng-template #IniciarSesion let-modal id="IniciarSesion">
  <div class="card-ingresar-body mt-5" style="width: 80%; margin-left: 10%; margin-right: 10%; text-align: center;">
    <h4 style="width: 80%; margin-left: 10%;"><strong>¡Hola artista! Para comprar, ingresa a tu cuenta</strong> </h4>
    <button class="btn btn-primary btn-lg btn-block mt-5" [routerLink]="['/email-por-confirmar']"
      (click)="modal.dismiss('Ingresar click')">Crear una cuenta</button>
    <button class="btn btn-link btn-lg btn-block mt-3 mb-5" [routerLink]="['/login', {'returnUrl': '/eventos'}]"
      (click)="modal.dismiss('Ingresar click')">Ingresar</button>
  </div>
</ng-template>

<ng-template #AlertaCovid let-modal id="AlertaCovid">
  <div class="card-ingresar-body mt-5" style="width: 100%;  text-align: center;">
    <h4 style="width: 80%; margin-left: 10%;"><strong>Artista por Covid-19 las sesiones están postergadas por tiempo indefinido.  <br>
      <br>
    #LosExtrañamos
  </strong> </h4>
    <br>
    <button class="btn btn-link btn-lg btn-block mt-3 mb-5 " style="width: 80%; margin-left: 10%;" [routerLink]="['/portafolio']"
      (click)="modal.dismiss('Go home click')">Ir a DrinkArt Home</button>
  </div>
</ng-template>


<ng-template #Pagar let-modal id="pagar">
  <div class="card-ingresar-body mt-5" style="width: 80%; margin-left: 10%; margin-right: 10%; text-align: center;">
    <h4 style="width: 80%; margin-left: 10%;"><strong>Artista elige el medio de pago de </strong>
      ${{eventoPorReservar.precio*getCantidad(eventoPorReservar.id)}}. </h4>
    <button class="btn btn-primary btn-lg btn-block mt-5" (click)="pagar()">Mercado Pago</button>
    <button class="btn btn-link btn-lg btn-block mt-3 mb-3" (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseInformacionPago">Otro medio</button>
  </div>
  <div id="collapseInformacionPago" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-body" style="text-align: center; width: 80%; margin-left: 10%;">
        <h6>Te enviaremos tu reservación por correo con las instrucciones de pago.</h6>
        <button class="btn btn-outline-dark btn-lg btn-block mt-3" (click)="enviarReservacion()"> Finalizar
        </button>
      </div>
      <div *ngIf="mensaje" class="alert alert-success mt-2" role="alert">
        <div class="row">
          <div class="col-sm-10"> <strong>Listo! </strong>
            {{mensaje + '. Redirigiendo a mis reservaciones...'}} seg. </div>
          <div class="col-sm-2">
            <button type="button" class="close" (click)="cleanError()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

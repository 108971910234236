import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule} from '@angular/material/card';
import { MatRadioModule} from '@angular/material/radio';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BasicAuthInterceptor } from './services/basic-auth-interceptor.service';

import { FooterComponent } from './layout/footer/footer.component';
import { TopBarComponent } from './layout/top-bar/top-bar.component';

import { HomeComponent } from './pages/home/home.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { BlogComponent } from './pages/blog/blog.component';


import { OutRegisterComponent } from './users/out-register/out-register.component';
import { EmailPorConfirmarComponent } from './users/email-por-confirmar/email-por-confirmar.component';
import { RestablecerPasswordComponent } from './users/restablecer-password/restablecer-password.component';
import { PasswordPorRestablecerComponent } from './users/password-por-restablecer/password-por-restablecer.component';
import { LoginComponent } from './users/login/login.component';
import { PortafolioComponent } from './pages/portafolio/portafolio.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { MisComprasComponent } from './pages/mis-compras/mis-compras.component';
import { MisEventosComponent } from './pages/mis-eventos/mis-eventos.component';
import { PropuestaArtisticaComponent } from './pages/propuesta-artistica/propuesta-artistica.component';
import { PreguntasFrecuentesComponent } from './pages/preguntas-frecuentes/preguntas-frecuentes.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    TopBarComponent,
    HomeComponent,
    ContactoComponent,
    OutRegisterComponent,
    EmailPorConfirmarComponent,
    RestablecerPasswordComponent,
    PasswordPorRestablecerComponent,
    EventosComponent,
    BlogComponent,
    PortafolioComponent,
    ClickOutsideDirective,
    MisComprasComponent,
    MisEventosComponent,
    PropuestaArtisticaComponent,
    PreguntasFrecuentesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    ImageCropperModule,
    MatCardModule,
    MatRadioModule,
    NgbModule,
    MatCheckboxModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }

<footer class="footer text-center mt-2" style="position: relative;" id="pie">
    <div class="image-footer-lg">
        <img src="assets\large\footer.png" style="width: 100%; height: 100%;" alt="">
    </div>
    <div class="image-footer-sm">
        <img src="assets\large\footer-sm.png" style="width: 100%; height: 100%;" alt="">
    </div>
    <div class="container" >

        <div class="row">
            <!-- Footer Location-->
            <div class="col-lg-4 mb-2 mb-lg-0">
                <h4 class="text-uppercase mb-3">Nosotros</h4>
                <a style="color: white;" [routerLink]="['/avisos-privacidad']"> Aviso de privacidad</a>
                <br>
                <a style="color: white;" [routerLink]="['/FAQ']"> Preguntas frecuentes</a>
                <br>
                <a style="color: white;" [routerLink]="['/TyC']"> Términos y condiciones</a>
                <br>
                <img style="width: 100px; height: auto; margin-top: 10px;" src="assets\images\logo_da.png">
            </div>
            <!-- Footer Social Icons-->
            <div class="col-lg-4 mb-2 mb-lg-0">
                <h4 class="text-uppercase mb-3">Nuestras redes</h4>
                <a class="btn btn-outline-light btn-social mx-1" href="https://www.facebook.com/pages/category/Art/DRINK-ART-103520094359154/" target="_blank"><i class="fab fa-fw fa-facebook-f"></i></a>
                <a class="btn btn-outline-light btn-social mx-1" href="https://www.instagram.com/drink_art_drink_art/?hl=es-la" target="_blank"><i class="fab fa-instagram"></i></a>
                <p class="lead mb-0 mt-2">
                    <i class="fas fa-phone-volume"></i> <a style="color: white;" href="tel:4494139529">  +52 449 413 9529</a>  
                    <br />
                    <i class="fab fa-whatsapp"></i> <a style="color: white;" href="https://api.whatsapp.com/send?phone=+524494139529&text=Hola Artista, ocupo más info" target="_blank">  449 413 9529</a>  
                </p>
            </div>
            <!-- Footer About Text-->
            <div class="col-lg-4">
                <h4 class="text-uppercase mb-3">Info</h4>
               ¡Música, drinks & arte! <br> Drink Art es una experiencia hecha especialmente para ti donde podrás relajarte y sacar el artista que llevas dentro 
               <br>¡Atrévete a vivirla! <br> <label class="mt-3" style="font-style: italic">"CONFÍA EN EL PROCESO Y AMARÁS EL RESULTADO"</label>                               
            </div>
        </div>
    </div>
</footer>
<!-- Copyright Section-->
<div class="copyright py-4 text-center text-white">
    <div class="container"><small>Copyright © Drink Art!</small></div>
</div>


<div class="container">

  <h2 class="titulo-plumon mb-5" style="margin-top: 130px;">Términos y condiciones</h2>
  <div class="row" class="mt-1 mb-1 p-5"
    style="text-align: center; background-color: white; color: black; border-radius: 5px;">
    <ul class="text-left" style="font-style: italic;">GENERALES <br>
      <li>Todas las obras de arte que manejamos están basadas en artistas que inspiran y nos permiten volar la
        imaginación, crea tu propia versión.
      <li>Para vivir la experiencia Drink Art no necesitas saber pintar, confía en ti, activa tu lado creativo y relájate.
      <li>Drink Art es para todas las edades.
      <li>El drink es exclusivo para mayores de edad.
      <li>Drink Art es una experiencia para relajarte y olvidarte del mundo exterior convirtiéndote en artista.
    </ul>
    <ul class="text-left" style="font-style: italic;">DRINK ART HOME <br>
      <li>Una vez elegido y confirmado tu kit no hay cambios de obra de arte.
      <li>El tiempo para documentación a la paquetería dependerá del flujo en el momento de confirmar el pago y la llegada
        a domicilio dependerá directamente de la paquetería.
      <li>La pintura acrílica debe mantenerse cerrada para evitar que se seque.
      <li>Lienzo de mdf tamaño 40x30 cm con bastidor para colgarse.
      <li>El drink es base alcohol, para consumo exclusivo de mayores de edad.
      <li>El lienzo esta trazado a mano y a lápiz para tu guía.
    </ul>
    <ul class="text-left" style="font-style: italic;">DRINK ART SESSIONS <br>
      <li>Una vez confirmado tu lugar no hay cambios de fecha de sesión.
      <li>En caso de no asistir a la sesión no habrá reembolso.
      <li>En caso de no poder asistir a la sesión confirmada podrá asistir alguien más pero en misma fecha y sesión
        confirmada.
      <li>Las sesiones cambian de spot a manera de sorpresa, te notificaremos.
      <li>El lienzo esta trazado a mano y a lápiz como guía.
      <li>Las sesiones cuentan con una asesoría artística, no es una clase de arte.
    </ul>
  </div>
  
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { User } from 'src/app/models/user';
import { CarritoService } from 'src/app/services/carrito.service';
import { PagesService } from 'src/app/services/pages.service';
import { AuthenticationService} from 'src/app/services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
  providers: [CarritoService]
})
export class BlogComponent implements OnInit {
  DJANGO_SERVER = `${environment.apiUrl}`;
  comentForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  currentUser: User;
  message;
  idProducto;
  producto: any=[];
  historia: any=[];
  comentarios: any=[];
  nombreProductoRechazado = "";
  carrito: any;
  allCategorias: any[];
  currentRate = 5;

  
  ctrl = new FormControl(null, Validators.required);

  toggle() {
    if (this.ctrl.disabled) {
      this.ctrl.enable();
    } else {
      this.ctrl.disable();
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiCarrito: CarritoService,
    private apiPages: PagesService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) { 
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.paramMap.subscribe(params => {
      this.idProducto = params.get('idProducto');
      this.getProducto(this.idProducto);
      this.getHistoria(this.idProducto);
    });
  }

  ngOnInit(): void {

    this.comentForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      comentario: ['', Validators.required],
    });
    this.getComentarios();
    this.categorias();
  }

  AgregarProducto(producto, modal){
    if (!this.currentUser){
      this.nombreProductoRechazado = producto.nombre
      this.modalService.open(modal);
      return;
    }
    this.apiCarrito.addProductToCar(this.currentUser.id, producto.id).subscribe(
      (data) => {
        this.carrito = data;
        document.getElementById('openNavButton').click();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getComentarios = () => {
    this.apiPages.getAllComents(this.idProducto).subscribe(
      data => { 
        this.comentarios = data;
       },
      error => {console.log(error); }
    );
  }

  getProducto = (idProducto) => {
    this.apiCarrito.getProductoByPk(idProducto).subscribe(
      data => { this.producto = data; },
      error => {console.log(error); }
    );
  }
  getHistoria = (idProducto) => {
    this.apiPages.getHistoriaProductoByPk(idProducto).subscribe(
      data => { this.historia = data; },
      error => {console.log(error); }
    );
  }

  get f() { return this.comentForm.controls; }

  cleanError(){
    this.message = "";
  }

  onSubmit() {
    this.submitted = true;

    if (this.comentForm.invalid) {
      return;
    }

      this.loading = true;
      this.apiPages.addComment(
        this.f.nombre.value,
        this.f.comentario.value,
        this.currentRate,
         this.currentUser.id,
          this.idProducto).subscribe(
        data => {
          this.getComentarios();
          this.message = "Su comentarios se agregó correctamente!";
          this.submitted = false;
          this.comentForm.reset();
        },
        error => {
          console.log(error);
          this.error = error;
        }
      );
  }

  categorias() {
    this.apiCarrito.getCategorias().subscribe(
      (data) => {
        this.allCategorias = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbAccordion, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css']
})
export class EventosComponent implements OnInit {
  DJANGO_SERVER = `${environment.apiUrl}`;
  list: any = [];
  p = 1;
  lugares_reservados: any = [];
  currentUser: User;
  nombreProductoRechazado = "";
  eventoPorReservar: any = [];
  reservacionGenerada: any = [];
  isCollapsed = true;
  mensaje = "";

  t;


  constructor(
    private carritoService: CarritoService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.getList();
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() { }


  getList = () => {
    this.carritoService.getAllEventos().subscribe(
      (data) => {
        this.list = data;
        this.lugares_reservados = [];
        for (let item of this.list) {
          this.lugares_reservados.push({ 'id_evento': item.id, 'cantidad': 1 })
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  ReservarEvento(evento, modal, pagar, alerta) {
    //this.modalService.open(alerta);
    //return;

    this.isCollapsed = true;
    this.eventoPorReservar = [];
    if (!this.currentUser) {
      this.nombreProductoRechazado = evento.nombre
      this.modalService.open(modal);
      return;
    }
    this.eventoPorReservar = evento;
    this.modalService.open(pagar);
    return;
  }

  aumentarLugar(id) {
    this.lugares_reservados.find(evento => evento.id_evento === id).cantidad += 1;
  }

  decrementarLugar(id) {
    const cantidad = this.lugares_reservados.find(evento => evento.id_evento === id).cantidad;
    if (cantidad >= 2)
      this.lugares_reservados.find(evento => evento.id_evento === id).cantidad -= 1;
  }

  getCantidad(id) {
    const evento = this.lugares_reservados.find(evento => evento.id_evento === id)
    return evento.cantidad
  }

  pagar() {
    this.carritoService.reservarEventoUsuario(this.currentUser.id, this.eventoPorReservar.id, this.getCantidad(this.eventoPorReservar.id)).subscribe(
      (data) => {
        this.reservacionGenerada = data;
        window.open(this.DJANGO_SERVER + '/carrito/pagar_reservacion/' + this.reservacionGenerada.id, "_self");
      },
      (error) => {
        console.log(error);
      }
    );
    this.modalService.dismissAll();
  }

  enviarReservacion() {
    this.carritoService.reservarEventoUsuario(this.currentUser.id, this.eventoPorReservar.id, this.getCantidad(this.eventoPorReservar.id)).subscribe(
      (data) => {
        this.reservacionGenerada = data;

        this.carritoService.enviarReservacion(this.reservacionGenerada.id).subscribe(
          (data) => {
            window.open(this.DJANGO_SERVER + '/carrito/imprimir_reservacion/' + this.reservacionGenerada.id, "_blank");
            this.mensaje = data.Success;
            setTimeout(() => {
              this.cleanError();
              this.modalService.dismissAll();
              this.router.navigate(['/mis-eventos']);
            }, 6000);

          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }



  ngOnDestroy() {
    if (this.t != undefined)
      clearTimeout(this.t);
  }

  cleanError() {
    this.mensaje = "";
  }

  isDateAfter(dateStr) {
    let now: Date = new Date();
    let dateEvent: Date = new Date(dateStr);
    return (now > dateEvent);
  }

}

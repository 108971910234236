<div class="container mb-3" style="margin-top: 130px;">
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <div *ngIf="message" class="alert alert-success" role="alert">
        <strong>Correcto!</strong> {{message}}
        <button type="button" class="close" (click)="cleanError()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
  <div class="row">       
    <div class="col-md-6 offset-md-3 mt-5">
    <h1>{{ titulo }} </h1>
    <form [formGroup]="contactoForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>Nombre</label>
          <input type="text" formControlName="nombre" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
            <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
            <div *ngIf="f.nombre.errors.required">Por favor, ingresa tu nombre</div>
          </div>
        </div>                    
        <div class="form-group">
          <label>Email</label>
          <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">El email es obligatorio</div>
            <div *ngIf="f.email.errors.email">Por favor, ingresa tu Email</div>
          </div>
        </div>
        <div class="form-group">
          <label for="mensaje">Mensaje</label>
          <textarea class="form-control" formControlName="mensaje" rows="3" [ngClass]="{ 'is-invalid': submitted && f.mensaje.errors }"></textarea>
          <div *ngIf="submitted && f.mensaje.errors" class="invalid-feedback">
            <div *ngIf="f.mensaje.errors.required">Por favor, ingresa el Mensaje ( 6 Caracteres mínimo )</div>
          </div>
        </div>
        <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">Aceptar</button>
        </div>
      </form>
  </div>
</div>
</div>

import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mis-eventos',
  templateUrl: './mis-eventos.component.html',
  styleUrls: ['./mis-eventos.component.css']
})
export class MisEventosComponent implements OnInit {
  title = 'drinkart-app';
  DJANGO_SERVER = `${environment.apiUrl}`;
  list;
  updateReservacion:any;
  lista: any = [];
  currentUser: User;
  p = 1;
  message;
  submitted = false;

  reservacionPorPagar: any = [];
  isCollapsed = true;
  mensaje = "";

  cuentaRegresiva = 0;
  cuentaLimite = 10;
  t;
  

  constructor(
    private carritoService: CarritoService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal
    ){
    //this.updateActivity = {proyectoUp: '', actividadUp: '', finalizadaUp: ''};
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getDataForm();
  }

  ngOnInit(): void {

  }


  getDataForm(){
    let usuario = localStorage.getItem('user');
    usuario = JSON.parse(usuario);
    this.submitted = true;
    this.carritoService.getListaReservaciones(
      this.currentUser.email,
      '', 
      '',
      ''
      ).subscribe(
      data => { this.lista = data; 
      },
      error => {console.log(error); }
    );
  }

  getActivity = (idVenta, modal) => {
    this.getReservacionPorId(idVenta, modal);
  }


  getReservacionPorId(id, modal){
    this.carritoService.getReservacionPorPk(id).subscribe(
      data => { 
        this.updateReservacion = data; 
        this.modalService.open(modal, { scrollable: true });
        },
      error => {console.log(error); }
    );
  }


  deleteReservacion = () => {
    this.carritoService.deleteReservacionPorLista(this.updateReservacion.id).subscribe(
      data => {
        this.getDataForm();
      },
      error => {console.log(error); }
    );
    this.modalService.dismissAll();
    this.message = "Se eliminó la reservación";
    setTimeout(() => {
      this.cleanError()
    }, 6000);
  }

  PagarReservacion(reservacion, modal, pagar){
    this.isCollapsed = true;
    if (!this.currentUser){
      this.modalService.open(modal);
      return;
    }
    this.reservacionPorPagar = reservacion;
    this.modalService.open(pagar);
    return;
  }
  
  pagar(){
    window.open(this.DJANGO_SERVER +'/carrito/pagar_reservacion/' + this.reservacionPorPagar.id, "_self");
    this.modalService.dismissAll();
  }

  enviarReservacion(){
    this.carritoService.enviarReservacion(this.reservacionPorPagar.id).subscribe(
      (data) => {
        this.mensaje = data.Success;
      }
    );
  }




  cleanError(){
    this.message = "";
  }

  closeModal(modal){
    this.modalService.dismissAll();
  }

}


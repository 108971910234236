<div class="container" style="margin-top: 130px;">
  <div class="row">
    <div class="col-md-6 offset-md-3 mt-5">
      <h3 style="text-align: center;">{{ titulo }} </h3>
    </div>
  </div>



  <div class="row">
    <div class="col-md-4 offset-md-4 mt-1">
      <form class="mt-3" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>Cuenta de correo electrónico</label>
          <input type="email" formControlName="email" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">El email es obligatorio</div>
            <div *ngIf="f.email.errors.email">Por favor, ingresa tu cuenta de correo</div>
          </div>
        </div>
        <div class="form-group">
          <button [disabled]="submitted" class="btn btn-primary btn-lg btn-block">Aceptar</button>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 offset-md-4 mt-3">
      <div *ngIf="message" class="alert alert-success" role="alert">
        <strong>Perfecto!</strong> {{message}}
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbModal, NgbCarousel, NgbSlideEvent, NgbSlideEventSource  } from '@ng-bootstrap/ng-bootstrap';
import { PagesService } from 'src/app/services/pages.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';





@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  title = 'drinkart-app';
  DJANGO_SERVER = `${environment.apiUrl}`;
  listaActivasUno:any = [];
  listaActivasDos:any = [];
  listaActivasTres:any = [];
  lista: any = [];
  currentUser: User;
  hideBanner: Boolean = false;
  showMovilVideo: Boolean = false;
  cuentaRegresiva = 0;
  duracionVideo = 11;
  t;
  eventosSeccionIndex = 1;

  contactoForm: FormGroup;
  loading = false;
  submitted = false;
  terminado = false;
  titulo = 'Envianos un mensaje!';
  message;

  constructor(
    private apiPages: PagesService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder
    ){
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getDataForm();
    if ((sessionStorage.getItem('videoFinalizado') != 'y') )
    this.hideBanner = true;

    if (this.isIphone()) 
      this.hideBanner = false;
    
    this.showMovilVideo = window.innerWidth < 720;
    
  }


  ngOnInit(): void {
    this.contactoForm = this.formBuilder.group({
        email: ['', Validators.required],            
        cantidad: ['', [Validators.required]],
        direccion: ['', [Validators.required]]
    });
}
get f() { return this.contactoForm.controls; }

  videoEnded(){
    this.hideBanner= false;
    sessionStorage.setItem('videoFinalizado', 'y');
  }
  getDataForm(){
    this.apiPages.getListaPropuestas().subscribe(
      data => { this.lista = data; 
                this.llenarListaImagenesActivas(this.lista)},
      error => {console.log(error); }
    );
  }

 isIphone():boolean{
  return navigator.vendor ===  "Apple Computer, Inc."
 } 

  onSubmit() {
    this.submitted = true;
    this.terminado = true;
    if (this.contactoForm.invalid) {
        this.terminado = false;
        return;
    }
    this.apiPages.solicitarCotización(this.f.email.value, this.f.cantidad.value, this.f.direccion.value).subscribe(
      data => {
        this.message = "Su solicitud se generó correctamente, te enviaremos la cotización lo antes posible!";
      },
      error => {
        console.log(error);
        this.terminado = false;
      }
    );
}


cleanError(){
  this.message = "";
}


  llenarListaImagenesActivas(lista){
    this.listaActivasUno = [];
    this.listaActivasDos = [];
    this.listaActivasTres = [];
    let index = 1;
    for (let item of lista) 
    {
      if (item.activo){
        if (index%3 === 1)
          this.listaActivasUno.push(item)
        if (index%3 === 2)
          this.listaActivasDos.push(item)
        if (index%3 === 0)
          this.listaActivasTres.push(item)
      }
      index++;
    }
  }

//Carrousel
  paused = true;
  unpauseOnArrow = true;
  pauseOnIndicator = false;
  pauseOnHover = true;
  showNavigationArrows = false;
  showNavigationIndicators = false;

 
  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;
  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }





  cambiarEvento(index){
    this.eventosSeccionIndex = index;
  }


}
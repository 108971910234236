import { Component, OnInit, ViewChild } from '@angular/core';
import { CarritoService} from 'src/app/services/carrito.service';
import { AuthenticationService} from 'src/app/services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from 'src/app/services/pages.service';


@Component({
  selector: 'app-portafolio',
  templateUrl: './portafolio.component.html',
  styleUrls: ['./portafolio.component.css'],
  providers: [CarritoService],
})
export class PortafolioComponent implements OnInit {
  DJANGO_SERVER = `${environment.apiUrl}`;
  list: any = [];
  carrito: any;
  p = 1;
  currentUser: User;
  nombreProductoRechazado = "";
  allCategorias: any[];
  categoria: any;
  id;
  producto: any=[];
  historia: any=[];
  
  constructor(
    private carritoService: CarritoService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private apiCarrito: CarritoService,
    private route: ActivatedRoute,
    private apiPages: PagesService,
  ) {
    // this.getList();
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.categoria = params['id'];
      if (this.categoria === undefined) 
      this.categoria = 1;
      this.filtrarCategoria();
    });
    this.categorias();
  }

  
  getList = () => {
    this.carritoService.getAllProducts().subscribe(
      (data) => {
        this.list = data;
      },
      (error) => {
        console.log(error);
      }
    );
  };

  AgregarProducto(producto, modal){
    if (!this.currentUser){
      this.nombreProductoRechazado = producto.nombre
      this.modalService.open(modal);
      return;
    }
    this.carritoService.addProductToCar(this.currentUser.id, producto.id).subscribe(
      (data) => {
        this.carrito = data;
        document.getElementById('openNavButton').click();
      },
      (error) => {
        console.log(error);
      }
    );
    }

    AgregarProductoModal(producto, modal){
      if (!this.currentUser){
        this.nombreProductoRechazado = producto.nombre
        this.modalService.open(modal);
        return;
      }
      this.carritoService.addProductToCar(this.currentUser.id, producto.id).subscribe(
        (data) => {
          this.carrito = data;
          document.getElementById('openNavButton').click();
        },
        (error) => {
          console.log(error);
        }
      );
    }

  categorias() {
    this.apiCarrito.getCategorias().subscribe(
      (data) => {
        this.allCategorias = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  filtrarCategoria(){
    this.apiCarrito.filtroCategoria(this.categoria).subscribe(
      (data) => {
        this.list = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  abrirDetalleModal = (modal, idProducto) => {
    this.getProducto(idProducto);
    this.getHistoria(idProducto, modal);
  }

  getProducto = (idProducto) => {
    this.apiCarrito.getProductoByPk(idProducto).subscribe(
      data => { this.producto = data; },
      error => {console.log(error); }
    );
  }

  getHistoria = (idProducto, modal) => {
    this.apiPages.getHistoriaProductoByPk(idProducto).subscribe(
      data => { this.historia = data;
        this.modalService.open(modal, {  size: 'xl', backdrop: 'static', scrollable: true }); 
      },
      error => {console.log(error); }
    );
  }

  closeModal(modal){
    this.modalService.dismissAll();
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './services/auth-guard.service';

/**
 * Páginas para el usuario
 */
import { HomeComponent } from './pages/home/home.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { AboutComponent } from './pages/about/about.component';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { PortafolioComponent } from './pages/portafolio/portafolio.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { BlogComponent } from './pages/blog/blog.component';
import { MisComprasComponent } from './pages/mis-compras/mis-compras.component';
import { MisEventosComponent } from './pages/mis-eventos/mis-eventos.component';
import { PropuestaArtisticaComponent } from './pages/propuesta-artistica/propuesta-artistica.component';
import { PreguntasFrecuentesComponent } from './pages/preguntas-frecuentes/preguntas-frecuentes.component';
import { TerminosycondicionesComponent } from './pages/terminosycondiciones/terminosycondiciones.component';
import { AvisoPrivacidadComponent } from './pages/aviso-privacidad/aviso-privacidad.component';

/**
 * Manejo de usuarios
 */

import { OutRegisterComponent } from './users/out-register/out-register.component';
import { EmailPorConfirmarComponent } from './users/email-por-confirmar/email-por-confirmar.component';
import { RestablecerPasswordComponent } from './users/restablecer-password/restablecer-password.component';
import { PasswordPorRestablecerComponent } from './users/password-por-restablecer/password-por-restablecer.component';
import { LoginComponent } from './users/login/login.component';



const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: HomeComponent},
  { path: 'mis-compras', component: MisComprasComponent, canActivate: [AuthGuard] },
  { path: 'mis-eventos', component: MisEventosComponent, canActivate: [AuthGuard] },
  { path: 'about', component: AboutComponent },
  { path: 'clientes', component: ClientesComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'out-register/ec50a102923aae8393370e43fd615b/:email', component: OutRegisterComponent },
  { path: 'email-por-confirmar', component: EmailPorConfirmarComponent },
  { path: 'password-por-reestablecer', component: PasswordPorRestablecerComponent },
  { path: 'reestablecer_password/:token/:email', component: RestablecerPasswordComponent },
  { path: 'portafolio', component: PortafolioComponent },
  { path: 'eventos', component: EventosComponent },
  { path: 'propuesta-artistica', component: PropuestaArtisticaComponent,  canActivate: [AuthGuard] },
  { path: 'blog/:idProducto', component: BlogComponent },
  { path: 'FAQ', component: PreguntasFrecuentesComponent },
  { path: 'TyC', component: TerminosycondicionesComponent },
  { path: 'avisos-privacidad', component: AvisoPrivacidadComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

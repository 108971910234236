<div class="ayuda_artistica" [routerLink]="['/FAQ']">
</div>

<!-- <div class="overlay"></div> -->
<div style="min-height: 100%;  min-width: 100%;" [hidden]="!hideBanner">
  <video [autoplay]="true" [muted]="true" [controls]="false" height="100%" width="100%" (ended)="videoEnded()" *ngIf="!showMovilVideo">
    <source src="assets\videos\portada.mp4" type="video/mp4">
  </video>
  <video [autoplay]="true" [muted]="true" [controls]="false" height="100%" width="100%" (ended)="videoEnded()" *ngIf="showMovilVideo">
    <source src="assets\videos\portada_cel.mp4" type="video/mp4">
    </video>
</div>

<ngb-carousel class="carousel slide" [hidden]="hideBanner" [interval]="13000" style="outline: none;">
  <!-- <ng-template ngbSlide >
    <div class="carousel-item active">
      <img class="bg-portada-lg img-carousel-lg" src="assets/large/drink-art-0.png" alt="First slide">
      <img class="bg-portada-sm img-carousel-sm" src="assets/large/drink-art-0-sm.jpg" alt="First slide">
      <div class="front_image_banner" [routerLink]="['/portafolio']" [queryParams]="{id:'4'}">
        <img src="assets/large/front_image-0.png"
          style="height: 80vh; width: auto; position: absolute; bottom: 0px; left: -35vh;  cursor: pointer;" alt="">
      </div>
    </div>
  </ng-template> -->
  <ng-template ngbSlide>
    <div class="carousel-item active">
      <img class="bg-portada-lg img-carousel-lg" src="assets/large/drink-art-1.png" alt="Season slide">
      <img class="bg-portada-sm img-carousel-sm" src="assets/large/drink-art-1-sm.jpg" alt="Season slide">
      <div class="front_image_banner">
        <img src="assets/large/front_image-1.png"
          style="height: 80vh; width: auto; position: absolute; bottom: 0px; left: -35vh;" alt="">
      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="carousel-item active">
      <img class="bg-portada-lg img-carousel-lg" src="assets/large/drink-art-2.png" alt="Second slide">
      <img class="bg-portada-sm img-carousel-sm" src="assets/large/drink-art-2-sm.jpg" alt="Second slide">
      <div class="front_image_banner">
        <img src="assets/large/front_image-2.png"
          style="height: 80vh; width: auto; position: absolute; bottom: 0px; left: -35vh;" alt="">
      </div>
      <!-- <div class="container">
        <div class="carousel-caption text-right p-0">
          <div>
            <button class="log-btn-modal" [routerLink]="['/portafolio']" role="button"> Ver obras </button>
          </div>
        </div>
      </div> -->
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="carousel-item active">
      <img class="bg-portada-lg img-carousel-lg" src="assets/large/drink-art-3.png" alt="Third slide">
      <img class="bg-portada-sm img-carousel-sm" src="assets/large/drink-art-3-sm.jpg" alt="Third slide" [routerLink]="['/portafolio']" [queryParams]="{id:'6'}">
      <div class="front_image_banner">
        <img src="assets/large/front_image-3.png" [routerLink]="['/portafolio']" [queryParams]="{id:'6'}"
          style="height: 80vh; width: auto; position: absolute; bottom: 0px; left: -35vh; cursor: pointer;" alt="" >
      </div>
      <!-- <div class="container">
        <div class="carousel-caption text-right p-0">
          <div>
            <button class="log-btn-modal-green" [routerLink]="['/eventos']" role="button"> Ver sesiones </button>
          </div>
        </div>
      </div> -->
    </div>
  </ng-template>
</ngb-carousel>



<!-- Services-->
<section class="page-section" id="services"
  style="border-style: none; border-width: 2px;   position: relative; width: 100%;">
  <div
    style="position: absolute; bottom:-5rem; right: 0px; width: 5rem; height: 20rem; border-style: none; border-width: 2px;">
    <img src="assets\images\mancha_02.png" style="width: 100%; height: 100%;" alt="">
  </div>
  <div class="bg-portada-lg"
    style="position: absolute; top: 5rem; left: 0px; width: 5rem;  height: 20rem; border-style: none; border-width: 2px; ">
    <img src="assets\images\mancha_03.png" style="width: 100%; height: 100%;" alt="">
  </div>
  <div class="container">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">¡Atrévete a vivir la experiencia Drink Art!
      </h2>
      <h3 class="section-subheading text-muted">¿Quieres convertirte en un artista, crear tu obra favorita, 
        <br> mezclar colores y olvidarte del mundo por un momento?
         <br> ¡Chécate esto!</h3>
    </div>
    <div class="row text-center">
      <div class="col-md-6">
        <button class="image_button" [routerLink]="['/eventos']">
          <img src="assets\images\home_sesiones.jpg" style="height: 300px; width: 300px;">
        </button>
        <br>
        <div style="text-align: center; width: 70%; margin-left: 15%;">
          <p class="text-muted" style="font-size: small; font-weight: 600; text-align: center;">
            Sesiones grupales acompañadas de diversión, música y drinks. Una experiencia única en lugares misteriosos; invita a quien tú quieras o
             atrévete a conocer nuevos amigos. 
           <br> ¡RESERVA YA UNA SESIÓN!
        </div>
      </div>
      <div class="col-md-6">
        <button class="image_button" [routerLink]="['/portafolio']">
          <img src="assets\images\home_home.jpg" style="height: 300px; width: 300px;">
        </button>
        <br>
        <div style="text-align: center; width: 70%; margin-left: 15%;">
          <p class="text-muted" style="font-size: small; font-weight: 600; text-align: center;">
            ¡Llevamos un Kit lleno de arte a tu casa! <br>Disfruta la experiencia el día que quieras, convirtiéndote en artista y decorando tus espacios favoritos desde tu casa!
             Compártelo con tus personas favoritas. <br> ¡TE VA A ENCANTAR!</p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Sessiones -->
<section class="page-section" id="eventos">
  <div class="text-center" style="background-color: #4133e7; padding: 50px;">
    <h2 class="section-heading text-uppercase" style="color: #63f5e6;;">¿Te interesa una sesión? </h2>
    <h3 class="section-subheading " style="color: white;">El arte se comparte.  ¿Dónde quieres vivir la experiencia?</h3>
    <div class="row text-center">

      <div class="col-md-4 mb-3">
        <button class="image_button">
          <img src="assets\images\home_sesiones_empresas.png" style="width: 100px; height: 100px;" alt=""
            (click)="cambiarEvento(1)">
        </button>
        <h4 class="my-3 text-white" style="cursor:pointer;" (click)="cambiarEvento(1)">Sesiones para <br> empresas</h4>
        <img src="assets\controls\boton_seleccion.png" style="width: 200px; height: 28px;"
          [hidden]="eventosSeccionIndex!=1">
      </div>

      <div class="col-md-4 mb-3">
        <button class="image_button">
          <img src="assets\images\home_sesiones_fiestas.png" style="width: 100px; height: 100px;" alt=""
            (click)="cambiarEvento(2)">
        </button>
        <h4 class="my-3 text-white" style="cursor:pointer;" (click)="cambiarEvento(2)">Sesiones para <br> fiestas</h4>
        <img src="assets\controls\boton_seleccion.png" style="width: 200px; height: 28px;"
          [hidden]="eventosSeccionIndex!=2">
      </div>

      <div class="col-md-4">
        <button class="image_button">
          <img src="assets\images\home_sesiones_casa.png" style="width: 100px; height: 100px;" alt=""
            (click)="cambiarEvento(3)">
        </button>
        <h4 class="my-3 text-white" style="cursor:pointer;" (click)="cambiarEvento(3)">Sesiones para <br> el hogar</h4>
        <img src="assets\controls\boton_seleccion.png" style="width: 200px; height: 28px;"
          [hidden]="eventosSeccionIndex!=3">
      </div>
    </div>
  </div>
  <div class="container-fluid  p-0" style="color: #ea5b34; background-color: white;">

    <div class="row no-gutters" [hidden]="eventosSeccionIndex!=1">
      <div class="col-lg-6 order-lg-2 text-white showcase-img"
        style="background-image: url('assets/img/sesiones-1.jpg');"></div>
      <div class="col-lg-1"></div>
      <div class="col-lg-5 order-lg-1 my-auto showcase-text"
        style="background-color: white; border: 2px; border-color: #ea5b34; border-style: solid;">
        <h2>Sesiones para empresas </h2>
        <p class="lead mb-2" style="color: #9a9a9a;">¿Aburrida la oficina amigo godín? Vuélvela parte de la familia Drink Art, pide tus Kits para que todos 
          tus colaboradores vivan una experiencia increíble.  </p>
        <h4>Pide tu cotización </h4>



        <form [formGroup]="contactoForm" (ngSubmit)="onSubmit()">
          <div class="row">


            <div class="col-lg-12">
              <div class="form-group">
                <input type="email" formControlName="email" class="form-control" placeholder="Correo"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">El email es obligatorio</div>
                  <div *ngIf="f.email.errors.email">Por favor, ingresa tu Email</div>
                </div>
              </div>
              <div class="form-group">
                <input type="number" class="form-control" formControlName="cantidad" placeholder="Cantidad de personas"
                  [ngClass]="{ 'is-invalid': submitted && f.cantidad.errors }" />
                <div *ngIf="submitted && f.cantidad.errors" class="invalid-feedback">
                  <div *ngIf="f.cantidad.errors.required">Por favor ingrese la cantidad de personas</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" formControlName="direccion" class="form-control"
                  placeholder="Dirección (Calle, Número, CP)"
                  [ngClass]="{ 'is-invalid': submitted && f.direccion.errors }" />
                <div *ngIf="submitted && f.direccion.errors" class="invalid-feedback">
                  <div *ngIf="f.direccion.errors.required">Por favor ingresa una dirección valida</div>
                </div>
              </div>

            </div>
            <div class="col-lg-12" style="position: relative;">


                  <button [hidden]="terminado" class="log-btn-modal-green">Enviar</button>


            </div>
          </div>
        </form>

        <div *ngIf="message" class="alert alert-info" role="alert">
          <strong>Correcto!</strong> {{message}}
        </div>
      </div>
    </div>

    <div class="row no-gutters" [hidden]="eventosSeccionIndex!=2">
      <div class="col-lg-6 text-white showcase-img" style="background-image: url('assets/img/sesiones-2.jpg');"></div>
      <div class="col-lg-5 order-lg-2 my-auto showcase-text"
        style="background-color: white; border: 2px; border-color: #ea5b34; border-style: solid; ">
        <h2>Sesiones en fiestas</h2>
        <p class="lead mb-0;" style="color: #9a9a9a;">¡Felicidades! <br> Queremos que tu fiesta sea algo inolvidable, comparte con tus invitados Drink Art Home
           y pasa una celebración increíble. </p>
           <button class="log-btn-modal-orange"> <a href="mailto:info@drinkart.com.mx" style="color: white;">Contáctanos</a></button>
      </div>
      <div class="col-lg-1 order-lg-3"></div>
    </div>


    <div class="row no-gutters" style="text-align: center;" [hidden]="eventosSeccionIndex!=3">
      <div class="col-lg-6 order-lg-2 text-white showcase-img"
        style="background-image: url('assets/img/sesiones-3.jpg');"></div>
      <div class="col-lg-1"></div>
      <div class="col-lg-5 order-lg-1 my-auto showcase-text"
        style="background-color: white; border: 2px; border-color: #ea5b34; border-style: solid;">
        <h2>Sesiones en casa</h2>
        <p class="lead mb-4" style="color: #9a9a9a;">Explora tu lado artístico desde la comodidad de tu casa cuidándote. 
          Pide tu Drink Art Home y pinta en familia. </p>
        <div >
          <img src="assets\images\home_cumple.png" style="height: 100px; width: 100px; "> 
          <img src="assets\images\home_family.png" style="height: 100px; width: 100px; margin-left: 15px;"> 
          <img src="assets\images\home_party.png" style="height: 100px; width: 100px; margin-left: 15px;"> 
        </div>
        <br>
        <button class="log-btn-modal-orange"> <a href="mailto:info@drinkart.com.mx" style="color: white;">Contáctanos</a></button>
      </div>

    </div>
  </div>
</section>

<!-- Team-->

<div class="page-section" id="team">

  <div class="container">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">Lo que dicen por ahí</h2>
      <h3 class="section-subheading text-muted m-0">Pásate a checar nuestros logros, experiencias y colaboraciones con otras marcas, 
        y claro, conoce a nuestros artistas más influyentes, por el gran esfuerzo con el que realizaron sus obras de arte.
       <br> ¡DRINK ART SOMOS TODOS!</h3>
    </div>
    <div class="row">
      <div class="multiple-items-carrousel-1 col-lg-4">
        <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)"
          [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
          <ng-template ngbSlide *ngFor="let l  of listaActivasUno ; index as i">

            <div class="team-member">
              <img class="mx-auto rounded-circle" [src]="DJANGO_SERVER + l.imagen" alt="" />
              <h4>{{l.profesion}}</h4>
              <p class="text-muted">{{l.historia}}</p>
              <a class="btn btn-link btn-sm"  style="color: #ea5b34; font-size: medium;" href={{l.facebook}} target="_blank"><i class="fas fa-link mr-2"></i>Visitar sitio</a>
              <a [hidden]="true" class="btn btn-social mx-2" href={{l.instagram}}><i class="fab fa-instagram"></i></a>
            </div>

          </ng-template>
        </ngb-carousel>
      </div>
      <div class="multiple-items-carrousel-2 col-lg-4">
        <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)"
          [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
          <ng-template ngbSlide *ngFor="let l  of listaActivasDos ; index as i">

            <div class="team-member">
              <img class="mx-auto rounded-circle" [src]="DJANGO_SERVER + l.imagen" alt="" />
              <h4>{{l.profesion}}</h4>
              <p class="text-muted">{{l.historia}}</p>
              <a class="btn btn-link btn-sm"  style="color: #ea5b34; font-size: medium;" href={{l.facebook}} target="_blank"><i class="fas fa-link mr-2"></i>Visitar sitio</a>
              <a [hidden]="true" class="btn btn-social mx-2" href={{l.instagram}}><i class="fab fa-instagram"></i></a>
            </div>

          </ng-template>
        </ngb-carousel>
      </div>
      <div class="multiple-items-carrousel-3 col-lg-4">
        <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)"
          [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
          <ng-template ngbSlide *ngFor="let l  of listaActivasTres ; index as i">

            <div class="team-member">
              <img class="mx-auto rounded-circle" [src]="DJANGO_SERVER + l.imagen" alt="" />
              <h4>{{l.profesion}}</h4>
              <p class="text-muted">{{l.historia}}</p>
              <a class="btn btn-link btn-sm" style="color: #ea5b34; font-size: medium;" href={{l.facebook}} target="_blank"><i class="fas fa-link mr-2"></i>Visitar sitio</a>
              <a [hidden]="true" class="btn btn-social mx-2" href={{l.instagram}}><i class="fab fa-instagram"></i></a>
            </div>


          </ng-template>
        </ngb-carousel>
      </div>


    </div>
  </div>
</div>



<!-- Contactanos -->

<section class="page-section bg-white m-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-10 text-center py-5">
        <h2 class="section-heading text-uppercase">¿Te interesa formar parte de Drink Art?</h2>
        <p class="lead text-gray-500 mb-5">Atrévete a compartir Drink Art y ayudanos a que más mexicanos se atrevan a convertir sus sueños en arte.</p>
        <button class="log-btn-modal-orange"> <a href="mailto:info@drinkart.com.mx" style="color: white;">Contáctanos</a></button>
        
      </div>
    </div>
  </div>
</section>

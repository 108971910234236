
  <section class="showcase">
    <div class="container-fluid p-0">
      <div class="row no-gutters">

        <div class="col-lg-6 order-lg-2 text-white showcase-img" style="background-image: url('assets/img/bg-showcase-1.jpg');"></div>
        <div class="col-lg-6 order-lg-1 my-auto showcase-text">
          <h2>This is who we are</h2>
          <p class="lead mb-0">We are automated vision analisys, focused on profitability of our costumers.  From concept and development to application, we are your partner.</p>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-lg-6 text-white showcase-img" style="background-image: url('assets/img/bg-showcase-2.jpg');"></div>
        <div class="col-lg-6 my-auto showcase-text">
          <h2>What we do?</h2>
          <p class="lead mb-0">We are the best team to build smart machines incorporated in automatic vision system.</p>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-lg-6 order-lg-2 text-white showcase-img" style="background-image: url('assets/img/bg-showcase-3.jpg');"></div>
        <div class="col-lg-6 order-lg-1 my-auto showcase-text">
          <h2>Our job is develop and integrate machine vision</h2>
          <p class="lead mb-0">We go from concept to reality for users who demand some deeper customization options.</p>
        </div>
      </div>
    </div>
  </section>


<div class="container" style="margin-top: 130px;">



  <div class="row">
    <div class="col-md-6 offset-md-3 mt-5" *ngIf='tokenValido'>

      <h3 style="text-align: center; width: 90%; margin-left: 5%;">Artista ahora puedes actualizar tu contraseña</h3>
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="mt-4">
        <div class="form-group">
          <label for="email">Cuenta de correo</label>
          <input type="email" formControlName="email" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" [ngModel]="email" readonly />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
          </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="password">Contraseña</label>
              <input type="password" formControlName="password" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Contraseña es requerida</div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="password">Confirmar contraseña</label>
              <input type="password" formControlName="passwordTwo" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.passwordTwo.errors }" />
              <div *ngIf="submitted && f.passwordTwo.errors" class="invalid-feedback">
                <div *ngIf="f.passwordTwo.errors.required">Confirmar contraseña</div>
              </div>
            </div>
          </div>

          <div>
            <button class="btn btn-primary btn-lg btn-block mr-2 mt-3" type="submit" [disabled]="submitted"> Actualizar contraseña </button>
          </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 offset-md-3 mt-3" *ngIf='noExiste'>
      <div class="alert alert-warning" role="alert">
        Este email ya se encuentra registrado. <a [routerLink]="['/password-por-reestablecer']"
          class="alert-link">Reestablecer contraseña</a>.
      </div>
    </div>

    <div class="col-md-6 offset-md-3 mt-3" *ngIf='!tokenValido'>
      <div class="alert alert-danger" role="alert">
        Su token ha expirado, favor de intentar nuevamente. <a [routerLink]="['/password-por-reestablecer']"
          class="alert-link">Reestablecer contraseña</a>.
      </div>
    </div>

      <div class="col-md-6 offset-md-3 mt-3">
        <div *ngIf="message" class="alert alert-success" role="alert">
          <strong>Success!</strong> {{message}}
          <button type="button" class="close" (click)="cleanError()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>


  </div>
</div>

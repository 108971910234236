import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AccesoService } from 'src/app/services/acceso.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  contactoForm: FormGroup;
  loading = false;
  submitted = false;
  titulo = 'Envianos un mensaje!';
  message;

  constructor(
    private formBuilder: FormBuilder,
    private api: AccesoService,
    private router: Router,
    ) { }

ngOnInit(): void {
        this.contactoForm = this.formBuilder.group({
            nombre: ['', Validators.required],            
            email: ['', [Validators.required, Validators.email]],
            mensaje: ['', [Validators.required, Validators.minLength(6)]]
        });
    }
    get f() { return this.contactoForm.controls; }
    
    onSubmit() {
      this.submitted = true;
      if (this.contactoForm.invalid) {
          return;
      }
      this.api.sendEmailData(this.f.nombre.value, this.f.email.value, this.f.mensaje.value).subscribe(
        data => {
          this.message = "Su mensaje se envió, gracias por contactarnos!";
          setTimeout(() => {
            this.router.navigate(['']);
          }, 3000);
        },
        error => {
          console.log(error);
        }
      );
      
      
  }

  cleanError(){
    this.message = "";
  }

}

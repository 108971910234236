import { Component, OnInit } from '@angular/core';
import { CarritoService} from 'src/app/services/carrito.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mis-compras',
  templateUrl: './mis-compras.component.html',
  styleUrls: ['./mis-compras.component.css']
})
export class MisComprasComponent implements OnInit {
  title = 'drinkart-app';
  list;
  updateVenta:any;
  productosVenta: any = [];
  listaVentas: any = [];
  currentUser: User;
  submitted = false;
  p = 1;
  message;
  DJANGO_SERVER = `${environment.apiUrl}`;

  constructor(
    private apiCarrito: CarritoService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal
    ){
    //this.updateActivity = {proyectoUp: '', actividadUp: '', finalizadaUp: ''};
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(): void {
    this.getDataForm();
  }



  getDataForm(){
    let usuario = localStorage.getItem('user');
    usuario = JSON.parse(usuario);
    this.submitted = true;
    this.apiCarrito.getListaVentas(
      this.currentUser.email,
      '', 
      true, 
      '', 
      '', 
      ).subscribe(
      data => { this.listaVentas = data; 
        },
      error => {console.log(error); }
    );
  }

  getActivity = (idVenta, modal) => {
    this.getVentaPorId(idVenta, modal);
  }


  deleteVenta = () => {
    this.apiCarrito.deleteListaVentas(this.updateVenta.id).subscribe(
      data => {
        this.getDataForm();
      },
      error => {console.log(error); }
    );
    this.modalService.dismissAll();
    this.message = "Se eliminó la venta";
    setTimeout(() => {
      this.cleanError()
    }, 6000);
  }
  
  getProductosDeVenta(){
    this.apiCarrito.getProductosPorVenta(
      this.updateVenta.id
      ).subscribe(
      data => { this.productosVenta = data; 
      },
      error => {console.log(error); }
    );
  }

  getVentaPorId(id, modal){
    this.apiCarrito.getListaVentasPk(id).subscribe(
      data => { 
        this.updateVenta = data; 
        this.getProductosDeVenta();
        this.modalService.open(modal, { scrollable: true });
        },
      error => {console.log(error); }
    );
  }

  pagar(idVenta){
    window.open(this.DJANGO_SERVER+'/carrito/pagar_venta/' + idVenta, "_self");
  }


  cleanError(){
    this.message = "";
  }

  closeModal(modal){
    this.modalService.dismissAll();
  }

}


<div *ngIf='producto && historia'>


  <!-- <div class="div-hash">
    {{historia.hashtag}}
  </div> -->
  <!-- Page Content -->
  <div class="container blog" *ngIf="producto">

    <div style="position: absolute; top: 150px; left: 0px; width: 5rem;  height: 20rem; border-style: none; border-width: 2px; z-index: -100;">
      <img src="assets\images\mancha_03.png" style="width: 100%; height: 100%;" alt="">
    </div>
    <div style="position: absolute; bottom:-500px; right: 0px; width: 5rem;  height: 20rem; border-style: none; border-width: 2px; z-index: -100;">
      <img src="assets\images\mancha_02.png" style="width: 100%; height: 100%;" alt="">
    </div>

    <div style="margin-top: 120px;">

      <!-- Post Content Column -->
      

      <div class="row">
        <div class="col-md-8">

          <!-- Title -->
          <!-- <h1 class="mt-4">{{ producto.nombre }}</h1> -->
  
          <!-- Preview Image -->
          <img class="img-fluid rounded" [src]="DJANGO_SERVER+producto.imagen_full" alt="">
  
          <!-- <hr> -->
          <!-- Buttons -->
          <button type="button" class="btn btn-danger btn-lg btn-block mt-1" style="background-color: #ea5b34;" (click)="AgregarProducto(producto, IniciarSesion)">Agregar al carrito</button>
          <!-- <hr> -->
          <!-- Post Content -->
          <!-- <p class="lead"> {{historia.introduccion}} </p>
          <p class="lead"> {{historia.desarrollo}} </p>
          <p class="lead"> <strong> {{historia.conclusion}} </strong> </p>
  
          <hr> -->
  
  
        </div>
  
        <!-- Sidebar Widgets Column -->
        <div class="col-md-4 mt-4">
          <div class="row">
            <div class="col-md-12" style="height: 340px; overflow-y: scroll;">
              <h2 class="letra-titulo text-uppercase" style="text-align: center;">{{ producto.nombre }}</h2>
              <p class="letra-desc" style="text-align: justify;"> {{historia.introduccion}} </p>
              <p class="letra-desc" style="text-align: center;"> {{historia.desarrollo}} </p>
              <p class="letra-desc" style="text-align: center;"> {{historia.conclusion}} </p>
            </div>
          </div>
          <hr style="color: #9a9a9a; height:10px">
          <div class="row">
            <div class="col-md-12" style="text-align: center; margin-bottom: 1rem;">
              <span class="kit-incluye">{{historia.hashtag}}</span>
            </div>
            <div class="col-md-12" style="height: 340px; overflow-y: scroll;">
              <iframe src="https://open.spotify.com/embed/playlist/3d6hVyyqiGyFlLCaMZEHkg" width="100%" height="600px"
                frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            </div>
          </div>
          <!-- Categories Widget -->
          <!-- <div class="card my-4">
            <h5 class="card-header">Categorias</h5>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <ul class="list-unstyled mb-0">
                    <li *ngFor="let cat of allCategorias">
                      <a [routerLink]="[ '/portafolio']" [queryParams]="{id: cat.id}">{{cat.descripcion}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->
  
          <!-- Side Widget -->
          

  
        </div>


      </div>
 
     <hr style="margin-top: 3rem;">
      <div class="row" style="margin-top: 2rem;">
        <div class="col-lg-8 my-4">
          <!-- Comments Form -->
          <ngb-accordion #acc="ngbAccordion" activeIds="static-1" *ngIf='currentUser'>
            <ngb-panel id="static-1" title="" >
              <ng-template ngbPanelTitle>
                <span style="color: #4133e7;"> Cuéntanos tu experiencia </span>
              </ng-template>
              <ng-template ngbPanelContent>
                <form [formGroup]="comentForm" (ngSubmit)="onSubmit()">
                  <div class="form-group">
                    <label for="nombre">Nombre artístico</label>
                    <input class="form-control" rows="3" formControlName="nombre"
                      [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" maxlength="45" />
                    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                      <div *ngIf="f.nombre.errors.required">Captura tu nombre artístico</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="comentario">Comentario</label>
                    <textarea class="form-control" rows="3" formControlName="comentario"
                      [ngClass]="{ 'is-invalid': submitted && f.comentario.errors }" maxlength="200"></textarea>
                    <div *ngIf="submitted && f.comentario.errors" class="invalid-feedback">
                      <div *ngIf="f.comentario.errors.required">Captura un comentario</div>
                    </div>
                  </div>
                  <label>Califícanos(<b>{{currentRate}}</b>)</label><br>
                  <ngb-rating [(rate)]="currentRate" [max]="5">
                    <ng-template let-fill="fill" let-index="index">
                      <span class="star" [class.filled]="fill === 100">&#9733;</span>
                    </ng-template>
                  </ngb-rating>
                  <hr>
                  <button type="submit" class="btn btn-primary">Enviar</button>
                </form>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>

        <div class="col-lg-4">
          <div class="row mr-1 ml-1">
            <div class="card my-4" style="color: #9a9a9a;">
              <h4 class="section-heading card-header"  style="height: 74px; text-align: lef;; font-family: Arial, Helvetica, sans-serif; padding-top: 20px !important;">¡Eres un gran artista!</h4>
              <div class="card-body">
                <p>Si quieres formar parte de nuestro gran equipo de artistas nos puedes enviar una demostración de tu
                  trabajo para compartirla con nuestra gran comunidad.</p>
                <button class="log-btn-modal-orange" [routerLink]="['/propuesta-artistica']">Contáctanos</button>
                <!-- <button type="button" class="btn btn-link btn-lg btn-block" [routerLink]="['/propuesta-artistica']"><i
                    class="fab fa-fly"></i> Contactanos...</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Single Comment -->
      <div *ngIf="comentarios" class="row">
        <div class="media mb-5 mt-5 col-lg-8" *ngFor="let comment of comentarios">
          <div class="coment-icon">
            <ngb-rating [(rate)]="comment.rating" [max]="5" [readonly]="true">
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class.filled]="fill === 100">&#9733;</span>
              </ng-template>
            </ngb-rating>
          </div>
          <div class="media-body">
            <h5 class="mt-0">{{ comment.nombre_artistico }}</h5>
            {{ comment.comentario }}
          </div>
        </div>
      </div>
    </div>
    <!-- /.row -->

  </div>
  <!-- /.container -->

  <ng-template #IniciarSesion let-modal id="IniciarSesion">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Lo sentimos</h4>
      <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p><strong>No fue posible agregar el producto
          <span class="text-primary">"{{nombreProductoRechazado}}"</span> al carrito.</strong> </p>
      <p>Pero no te preocupes puedes <strong>Ingresar</strong> o <strong>Crear una cuenta</strong> para continuar con
        tus compras.
        <span class="text-danger">Para realizar una compra es necesario ingresar con tu cuenta.</span>
      </p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" [routerLink]="['/login', {'returnUrl': '/portafolio'}]"
        (click)="modal.dismiss('Ingresar click')">Ingresar</button>
      <button class="btn btn-outline-success" [routerLink]="['/email-por-confirmar']"
        (click)="modal.dismiss('Ingresar click')">Crear una cuenta</button>
    </div>

  </ng-template>

</div>
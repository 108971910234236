<div class="container">
  <div class="row">
    <div class="col-md-4 offset-md-4" style="margin-top: 150px;">
      <h3 style="text-align: center; width: 100%;">¡Hola Artista! <br> Para agregar al carrito, ingresa a tu cuenta</h3>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="mt-3">
        <div class="form-group">
          <div class="input-group mb-3">
            <input type="email" formControlName="email" class="form-control" placeholder="Correo electrónico"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          </div>
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email es requerido</div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group mb-3">
            <input type="password" formControlName="password" class="form-control" placeholder="Contraseña"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          </div>
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Contraseña es requerida.</div>
          </div>
        </div>
        <button [disabled]="loading" class="btn btn-primary btn-lg btn-block mt-2">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Iniciar sesión
        </button>
      </form>
      <div>
        <button [disabled]="loading" class="btn btn-link btn-lg btn-block mb-0">
          <a class="small" [routerLink]="['/password-por-reestablecer']">Olvidé mi contraseña</a>
        </button>
        <button [disabled]="loading" class="btn btn-link btn-lg btn-block mt-0 p-0">
          <a class="small" [routerLink]="['/email-por-confirmar']">Soy nuevo</a>
        </button>
      </div>

    </div>


  </div>

  <div class="row">
    <div *ngIf="error" class="alert alert-danger mt-3 mb-4 col-md-6 offset-md-3">
      Usuario o contraseña inválida.
      <button type="button" class="close" (click)="cleanError()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>

<link rel="stylesheet" href="//cdn.materialdesignicons.com/3.7.95/css/materialdesignicons.min.css">
<div class="container" style="margin-top: 130px;">
    <h2 class="titulo-plumon mb-5">Preguntas frecuentes</h2>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 grid-margin grid-margin-md-0">
                  <div class="card">
                    <div class="faq-block card-body">
                      <div class="container-fluid py-2">
                        <h5 class="mb-0">Acerca de Drink Art Home</h5>
                      </div>
                      <div id="accordion-4" class="accordion">
                        <div class="card">
                          <div class="card-header" id="headingOne-4">
                            <h5 class="mb-0">
                              <a data-toggle="collapse" data-target="#collapseOne-4" aria-expanded="true" aria-controls="collapseOne-4">
                                ¿Ocupo ser un artista para pintar una obra de arte de Drink Art Home?
                              </a>
                            </h5>
                          </div>
                          <div id="collapseOne-4" class="collapse show" aria-labelledby="headingOne-4" data-parent="#accordion-4">
                            <div class="card-body">
                              <p class="mb-0">Claro que no, todos somos artistas y nos expresamos de formas distintas.  Aunque, para que no te pierdas, tienes como guía el lienzo que está trazado con la imagen que
                                escogiste y en nuestra página tienes una imagen digital de la obra terminada. Recuerda que te llevamos la pintura necesaria para hacer tu obra de arte.  </p><p>
                            </p></div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="headingTwo-4">
                            <h5 class="mb-0">
                              <a data-toggle="collapse" data-target="#collapseTwo-4" aria-expanded="false" aria-controls="collapseTwo-4">
                                ¿Cuánto tarda en llegar el kit despues de que se acredita mi pago?
                              </a>
                            </h5>
                          </div>
                          <div id="collapseTwo-4" class="collapse" aria-labelledby="headingTwo-4" data-parent="#accordion-4">
                            <div class="card-body">
                              <p class="mb-0">
                                Nosotros realizamos el envío un día hábil después de que se acreditó el pago.  Una vez en paquetería te enviaremos por correo y sms tu número de guía.
                              </p><p>
                            </p></div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="headingThree-4">
                            <h5 class="mb-0">
                              <a data-toggle="collapse" data-target="#collapseThree-4" aria-expanded="false" aria-controls="collapseThree-4">
                                ¿Los pedidos tienen vigencia antes de ser pagados?
                              </a>
                            </h5>
                          </div>
                          <div id="collapseThree-4" class="collapse" aria-labelledby="headingThree-4" data-parent="#accordion-4">
                            <div class="card-body">
                              <p class="mb-0">Lamentablemente sí, por control interno cualquier pedido que no se acredite después de 15 días será eliminado.</p><p>
                            </p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> <!-- sub col -->
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="faq-block card-body">
                      <div class="container-fluid py-2">
                        <h5 class="mb-0">Acerca de Drink Art Sessions</h5>
                      </div>
                      <div id="accordion-2" class="accordion">
                        <div class="card">
                          <div class="card-header" id="headingOne-2">
                            <h5 class="mb-0">
                              <a data-toggle="collapse" data-target="#collapseOne-2" aria-expanded="true" aria-controls="collapseOne-2">
                                ¿Qué pasa si no puedo asistir a la sesión?
                              </a>
                            </h5>
                          </div>
                          <div id="collapseOne-2" class="collapse show" aria-labelledby="headingOne-2" data-parent="#accordion-2">
                            <div class="card-body">
                              <p class="mb-0"> Eso nos pone muy tristes, pero lamentablemente no habrá devolución ni cambio ya que preparamos todo el material para tu asistencia.</p><p>
                            </p></div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="headingTwo-2">
                            <h5 class="mb-0">
                              <a data-toggle="collapse" data-target="#collapseTwo-2" aria-expanded="false" aria-controls="collapseTwo-2">
                                ¿Necesito ser un artista para vivir una sesión?
                              </a>
                            </h5>
                          </div>
                          <div id="collapseTwo-2" class="collapse" aria-labelledby="headingTwo-2" data-parent="#accordion-2">
                            <div class="card-body">
                              <p class="mb-0">No te preocupes, en las sesiones hay una guía artística pero no es una clase. Recuerda que se pinta sobre lienzos de mdf con el trazo que se escoge.  
                                Al finalizar la sesión la pintura es tuya y como nuestro lienzo incluye un bastidor lo puedes poner donde quieras para presumirlo.
                              </p><p>
                            </p></div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="headingThree-2">
                            <h5 class="mb-0">
                              <a data-toggle="collapse" data-target="#collapseThree-2" aria-expanded="false" aria-controls="collapseThree-2">
                                ¿Facturan?
                              </a>
                            </h5>
                          </div>
                          <div id="collapseThree-2" class="collapse" aria-labelledby="headingThree-2" data-parent="#accordion-2">
                            <div class="card-body">
                              <p class="mb-0">Claro que sí, te puedes poner en contacto con uno de nuestros ejecutivos vía telefónica y él te apoyará.</p><p>
                            </p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           </div><!-- parent row -->
           <div class="row"  class="mt-4 mb-5 p-5" style="text-align: center; font-size: x-large; background-color: #ff935c; color: #fff; border-radius: 5px;">
            <p class="text-center">¿Necesitas ayuda? Llama a nuestro equipo de artistas galardonados al 4494139529</p> 
          </div> 
        </div>
<div class="container" style="margin-top: 150px; cursor: pointer;"  [routerLink]="['/portafolio']" [queryParams]="{id:'6'}">
  <img src="assets/images/banner_promo_kids.jpg" class="img-fluid bg-portada-lg" alt="Responsive image">
  <img src="assets/images/banner_promo_kids_movil.jpg" class="img-fluid bg-portada-sm" alt="Responsive image">
</div>
<!-- Portfolio Grid -->
<div class="container-fluid">
<section class="page-section" id="portfolio" style="padding-top: 20px !important;" >

    <!-- <div class="col-lg-3">
      <h4>Filtrar por categoria</h4>
      <div class="input-group">
        <select class="form-control" [(ngModel)]="categoria" >
          <option *ngFor="let categoria of allCategorias" [value]="categoria.id">
            {{ categoria.descripcion }}
          </option>
        </select>
        <span class="input-group-btn">
          <button class="btn" style="background-color: deeppink;" type="button" (click)="filtrarCategoria()"> 
            <span class="fa fa-search icon" style="color: white;"></span>
          </button>
        </span>
      </div>
    </div> -->
    <div class="text-center" *ngIf="categoria!=='6'">
      <h2 class="section-heading text-uppercase mt-3">
        <div class="animate__animated animate__backInDown">
          ¿CUÁL QUIERES PINTAR HOY?
        </div>
      </h2>
      <h3 class="section-subheading">
        ¡Que tus sueños se conviertan en arte, sólo confía en ti! 
      </h3>
    </div>

    <div class="text-center" *ngIf="categoria==='6'">
      <h2 class="section-heading text-uppercase mt-0">
        <div class="animate__animated animate__backInDown">
          Tus peques lo amarán
        </div>
      </h2>
      <h3 class="section-subheading">
        ¡Que sus sueños se conviertan en arte, sólo confía en ellos! 
      </h3>
    </div>
    <div class="row no-gutter portafolio-list">

      <!-- <div class="col-xl-4 col-lg-6 mb-1" *ngFor="let l of list">
        <div class="portfolio-item">
          <div class="portfolio-hover container">
            <img class="image overlay" [src]="DJANGO_SERVER+l.imagen_desc" alt="" />
            <div class="portfolio-caption" (click)="abrirDetalleModal(detalleModal)">
              <div class="portfolio-caption-heading mt-5">{{l.nombre}}</div>
              <div class="portfolio-caption-subheading" style="height: 50px;">
                {{l.descripcion}}
              </div>
              <div class="portfolio-caption-subheading mt-5">
                ${{l.precio}}
              </div>
              <div class="portafolio-btns">
                <button type="button" class="btn btn-primary btn-sm" style="background-color: #ea5b34;"
                  (click)="AgregarProducto(l, IniciarSesion)">Agregar al carrito</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="col-xl-4 col-lg-6" *ngFor="let l of list">
        <div class="portfolio-item">
          <div class="portfolio-hover">
            <div class="container">
              <img class="image" [src]="DJANGO_SERVER+l.imagen_desc" />
              <div class="overlay">
                  <div class="text" style="text-align: center;">{{l.descripcion}} <br> 
                    <a (click)="abrirDetalleModal(detalleModal, l.id)" class="icon" title="Drink Art! ">
                      <i class="fa fa-plus" style="cursor: pointer; color: wheat;" ></i>
                    </a>
                  </div>
              </div>
            </div>
            <div class="row portfolio-caption  m-0">
              <div class="col-md-6 portfolio-caption-heading">
                {{l.nombre}}
              </div>
              <div class="col-md-6">
                <div class="precio-portafolio">
                  ${{l.precio}}
                </div>
                <div class="portafolio-btns">
                  <button type="button" class="btn btn-sm" style="background-color: #ea5b34; color: white;"
                    (click)="AgregarProducto(l, IniciarSesion)">Agregar al carrito</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

</section>
</div>

<ng-template #IniciarSesion let-modal id="IniciarSesion">
  <!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Lo sentimos</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->
  <div class="card-ingresar-body mt-5" style="width: 80%; margin-left: 10%; margin-right: 10%; text-align: center;">
    <h4 style="width: 80%; margin-left: 10%;"><strong>¡Hola artista! Para comprar, ingresa a tu cuenta</strong> </h4>
    <button class="btn btn-primary btn-lg btn-block mt-5" [routerLink]="['/email-por-confirmar']" (click)="modal.dismiss('Ingresar click')">Crear una cuenta</button>
    <button class="btn btn-link btn-lg btn-block mt-3 mb-5" [routerLink]="['/login', {'returnUrl': '/portafolio'}]" (click)="modal.dismiss('Ingresar click')">Ingresar</button>
  </div>
</ng-template>

<ng-template #detalleModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="seeModal"></h5>
    <a (click)="closeModal(detalleModal)"><label style="color: #4133e7; font-size: 30px;">&times;</label></a>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-12">
            <!-- <img class="img-fluid" [src]="DJANGO_SERVER+producto.imagen_desc" alt=""> -->
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
              </ol>
              <div class="carousel-inner">
            
            
                <div class="carousel-item active">
                  <img class="img-carousel-sm" [src]="DJANGO_SERVER+producto.imagen_desc" alt="First slide">
                </div>
            
            
                <div class="carousel-item">
                  <img class="img-carousel-sm" [src]="DJANGO_SERVER+producto.imagen_trazo" alt="Second slide">
                </div>
            
                <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                  <span class="fa fa-chevron-left fa-2x" style="color:black; background-color: white; border-radius: 2px;"
                    aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                  <span class="fa fa-chevron-right fa-2x" style="color:black; background-color: white; border-radius: 2px;"
                    aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12" style="padding-top: 2rem; padding-bottom: 2rem;">
            <div class="row">
              <div class="col-md-2 col-sm-2 col-2" style="text-align: left;">
                <img width="80" height="auto" src="assets/images/NIVEL_{{historia.dificultad}}.png" alt="">
              </div>
              <div class="col-md-10 col-sm-10 col-10" style="text-align: right;">
                <span class="precio-modal">${{producto.precio}}  </span><button type="button" class="btn btn-sm" style="background-color: #ea5b34; color: white;" (click)="AgregarProductoModal(producto, IniciarSesion)">Agregar al carrito</button>
              </div>
              <!-- <div class="col-sm-6" style="text-align: center;">
                <div class="portafolio-btns">
                  <span class="precio-modal">${{producto.precio}}  </span><button type="button" class="btn btn-sm" style="background-color: #ea5b34; color: white;">Agregar al carrito</button>
                </div>  
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-12" style="text-align: center;">
            <h2 class="letra-titulo text-uppercase" style="color: #4133e7;">{{ producto.nombre }}</h2>
            <p class="letra-desc" style="text-align: justify;"> {{historia.introduccion}} </p><a (click)="closeModal(detalleModal)" [routerLink]="['/blog', producto.id]" style="color: #4133e7; font-style: italic; font-weight: 700;"> Quiero saber más...</a >
          </div>
        </div>
        <hr style="color: #9a9a9a; height:10px">
        <div class="row">
          <div class="col-md-12" style="text-align: center;">
            <span class="kit-incluye">TU KIT INCLUYE:</span>
          </div>
          <div class="col-md-8 offset-md-2">      
            <img class="img-fluid" src="assets/images/kit_kids.jpg" alt="" *ngIf = "producto.categoria===6">
            <img class="img-fluid" src="assets/images/kit.jpg" alt="" *ngIf = "producto.categoria!==6">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
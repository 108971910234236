<div class="container" >
  <div class="row">
    <div class="col-md-6 offset-md-3 mt-5">
      <h3 class="mb-4" style="text-align: center; margin-top: 75px;">Artista unos cuantos datos más y terminamos.</h3>
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" formControlName="email" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" [ngModel]="email" readonly />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="telefono">Teléfono (10 dígitos)</label>
            <input type="text" [pattern]="mascaraTelefono" formControlName="telefono" class="form-control" placeholder="Teléfono"
              [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }" />
            <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback">
              <div *ngIf="f.telefono.errors.required">Teléfono es reqerido</div>
              <div *ngIf="f.telefono.errors.pattern">Captura el teléfono a 10 digitos</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6">
            <label for="password">Contraseña</label>
            <input type="password" formControlName="password" class="form-control" placeholder="Contraseña"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Contraseña es requerida.</div>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="password">Confirma la contraseña</label>
            <input type="password" formControlName="passwordTwo" class="form-control" placeholder="Contraseña"
              [ngClass]="{ 'is-invalid': submitted && f.passwordTwo.errors }" />
            <div *ngIf="submitted && f.passwordTwo.errors" class="invalid-feedback">
              <div *ngIf="f.passwordTwo.errors.required">
                Confirmar contraseña es requerido.
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <small id="emailHelp" class="form-text text-muted">Artista por seguridad te recomendamos utilizar una contraseña única.</small>
          </div>
        </div>

        <div>
          <button class="btn btn-primary btn-lg btn-block mr-2 mt-3" type="submit">
            Finalizar registro
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 offset-md-3 mt-3">
      <div *ngIf="message" class="alert alert-success" role="alert">
        <strong>Muy bien!</strong> {{ message }}
        <button type="button" class="close" (click)="cleanError()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 offset-md-3 mt-3" *ngIf="yaExiste">
      <div class="alert alert-warning" role="alert">
        Este email ya se encuentra registrado.
        <a [routerLink]="['/password-por-reestablecer']" class="alert-link">Reestablecer contraseña</a>.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 offset-md-3 mt-3" *ngIf="!(errorPassword==='')">
      <div class="alert alert-danger" role="alert">
        {{errorPassword}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>

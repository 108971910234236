<div class="container" style="margin-top: 130px;">
  <h2 class="mt-4 ml-2">Mis eventos reservados</h2>

  <div class="row mb-2 mt-5">
    <div style="position: absolute; top: 150px; left: 0px; width: 5rem;  height: 20rem; border-style: none; border-width: 2px; z-index: -100;">
      <img src="assets\images\mancha_03.png" style="width: 100%; height: 100%;" alt="">
    </div>
    <div style="position: absolute; bottom:20px; right: 0px; width: 5rem;  height: 20rem; border-style: none; border-width: 2px; z-index: -100;">
      <img src="assets\images\mancha_02.png" style="width: 100%; height: 100%;" alt="">
    </div>
    <div class="col-lg-6"  *ngFor="let reservacion of lista">
      <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static;" style="background-color: white;">
          <strong *ngIf="!reservacion.pagado" class="d-inline-block mb-2 text-danger">Pago pendiente</strong>
          <strong *ngIf="reservacion.pagado" class="d-inline-block mb-2 text-success">Pagado</strong>
          <h3 class="mb-0">{{ reservacion.evento.nombre}}</h3> <p class="mb-0">${{reservacion.evento.precio}} x {{reservacion.lugares_reservados}}</p>
          <div class="mb-1 text-muted"> {{reservacion.evento.fecha_evento | date:'dd/MMM/yyy'}} </div>
          <button type="button" class="btn btn-primary"  *ngIf='!reservacion.pagado' 
          (click)='PagarReservacion(reservacion, IniciarSesion, Pagar)'>
            Pagar ${{reservacion.lugares_reservados*reservacion.evento.precio}}
          </button>
          <!-- <button type="button" class="btn btn-outline-danger"  *ngIf='reservacion.pagado'>Cancelar reservación</button> -->
          <button class="btn btn-outline-danger mt-1" *ngIf='!reservacion.pagado'
            (click)="getActivity(reservacion.id, deleteModal)"><i
            class="far fa-trash-alt" aria-hidden="true"></i></button>
        </div>
        <div class="col-auto mt-5" style="text-align: center;">
          <img style="width:160px; height:auto" [src]="DJANGO_SERVER+reservacion.evento.imagen_desc" alt="" />
        </div>
      </div>
    </div>
  </div>

</div>



<ng-template #deleteModal let-modal>
  <form (ngSubmit)="deleteReservacion()">
    <div class="modal-header">
      <h5 class="modal-title" id="deleteModalLabel">Eliminar reservación</h5>
    </div>
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
          Desea eliminar la reservación del evento:
          <strong><label>{{ updateReservacion.evento.nombre }}</label></strong> con total de: $
          <strong><label>{{ updateReservacion.evento.precio* updateReservacion.lugares_reservados }}</label></strong> y folio: #
          <strong><label>{{ updateReservacion.id }}</label></strong>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closeModal(deleteModal)">Cancelar</button>
      <button class="btn btn-danger mr-2">Eliminar</button>
    </div>
  </form>
</ng-template>

<ng-template #seeModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="deleteModalLabel">Reservación</h5>
  </div>
  <div class="modal-body">
    <div class="card">
      <div class="card-body mb-3">
        Folio: <strong><label>{{ updateReservacion.id }}</label></strong><br />
        Email: <strong><label>{{ updateReservacion.usuario.email }}</label></strong><br />
        Nombre del evento: <strong><label>{{ updateReservacion.evento.nombre }}</label></strong><br />
        <div class="form-group">
          <p>Precio unitario: <strong><label>{{ updateReservacion.evento.precio }}</label></strong><br /></p>
        </div>
        <div class="form-group">
          <p>Lugares reservados: <strong><label>{{ updateReservacion.lugares_reservados }}</label></strong><br /></p>
        </div>
        <div class="form-group">
          <p>Estatus de pago: <strong>
              <label *ngIf="updateReservacion.pagado">Pagado</label>
              <label *ngIf="!updateReservacion.pagado">Pendiente</label>
            </strong><br /></p>
        </div>
        <hr>
        <div style="right: 10px; position: absolute;">
          <p>Total: $
            <strong><label>{{ updateReservacion.evento.precio*updateReservacion.lugares_reservados }}</label></strong><br />
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal(seeModal)">Aceptar</button>
  </div>
</ng-template>


<ng-template #IniciarSesion let-modal id="IniciarSesion">
  <div class="card-ingresar-body mt-5" style="width: 80%; margin-left: 10%; margin-right: 10%; text-align: center;">
    <h4 style="width: 80%; margin-left: 10%;"><strong>¡Hola artista! Para comprar, ingresa a tu cuenta</strong> </h4>
    <button class="btn btn-primary btn-lg btn-block mt-5" [routerLink]="['/email-por-confirmar']" (click)="modal.dismiss('Ingresar click')">Crear una cuenta</button>
    <button class="btn btn-link btn-lg btn-block mt-3 mb-5" [routerLink]="['/login', {'returnUrl': '/eventos'}]" (click)="modal.dismiss('Ingresar click')">Ingresar</button>
  </div>
  </ng-template>


  <ng-template #Pagar let-modal id="pagar">
    <div class="card-ingresar-body mt-5" style="width: 80%; margin-left: 10%; margin-right: 10%; text-align: center;">
      <h4 style="width: 80%; margin-left: 10%;"><strong>Artista elige el medio de pago de </strong>  
        ${{reservacionPorPagar.evento.precio*reservacionPorPagar.lugares_reservados}}. </h4>
      <button class="btn btn-primary btn-lg btn-block mt-5"  (click)="pagar()">Mercado Pago</button>
      <button class="btn btn-link btn-lg btn-block mt-3 mb-3"
      (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseInformacionPago"
      >Otro medio</button>
    </div>
    <div id="collapseInformacionPago" [ngbCollapse]="isCollapsed">
      <div class="card">
        <div class="card-body" style="text-align: center; width: 80%; margin-left: 10%;">
          <h6>Te enviaremos tu reservación por correo con las instrucciones de pago.</h6>
          <button class="btn btn-outline-dark btn-lg btn-block mt-3"  (click)="enviarReservacion()"> Estoy de acuerdo </button>
        </div>
        <div *ngIf="mensaje" class="alert alert-success mt-2" role="alert">
          <div class="row">
            <div class="col-sm-10"> <strong>¡Listo! </strong> {{mensaje}} </div>
            <div class="col-sm-2">
              <button type="button" class="close" (click)="modal.dismiss('Correo enviado')" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>


<div class="container" style="margin-top: 150px; background-color: white;">
  <h2 class="mt-4 ml-2 text-center">Mis compras</h2>
  <div class="row mb-5">
    <div class="col-lg-12 mt-3">
      <div class="table-responsive mt-3" *ngIf='listaVentas.length > 0'>
        <table class="table table-striped table-borderless">
          <thead style="border-style: solid; border-color: #ea5b34; border-width: 1px;">
            <tr>
              <th scope="col ">
                <h5 class="set-botones"># Folio </h5>
              </th>
              <th scope="col ">
                <h5 class="set-botones">Total </h5>
              </th>
              <th scope="col" >
                <h5 class="set-botones">Fecha compra </h5>
              </th>
              <th scope="col">
                <h5 class="set-botones">Pagada </h5>
              </th>
              <th scope="col">
                <h5 class="set-botones">Entregada </h5>
              </th>
              <th scope="col">
                <h5 class="set-botones"> </h5>
              </th>
            </tr>
          </thead>
          <tbody class="mb-4">
            <tr *ngFor="let l of listaVentas | paginate: { itemsPerPage: 3, currentPage: p }">
              <th>{{ l.id }}</th>
              <td>$ {{ l.total }}</td>
              <td>{{ l.fecha_registro| date:'yyyy-MM-dd' }}</td>


              <td class="" *ngIf='l.pagado'><i class="far fa-check-circle fa-2x"></i></td>
              <td class="" *ngIf='!l.pagado'><i class="far fa-circle fa-2x"></i></td>

              <td class="" *ngIf='l.entregado'><i class="far fa-check-circle fa-2x"></i></td>
              <td class="" *ngIf='!l.entregado'><i class="far fa-circle fa-2x"></i></td>

              <td>
                <div class="set-botones">
                  <button class="btn btn-link" (click)="getActivity(l.id, seeModal)">
                    Ver detalle</button>
                  <ng-template #popCancelarPedido>
                    <button (click)="getActivity(l.id, deleteModal)"
                      class="btn btn-sm btn-outline-danger xs-margin"
                      style="border-radius: 10px; width: 100%;"> Eliminar pedido
                    </button>
                    <button (click)="pagar(l.id)"
                    class="btn btn-sm btn-primary xs-margin mt-2"
                    style="border-radius: 10px; width: 100%;"> Mercadopago
                  </button>
                    <a [href]="DJANGO_SERVER+'/carrito/imprimir_venta/' + l.id" target="_blank"
                    class="btn btn-sm btn-outline-success xs-margin mt-2"
                    style="border-radius: 10px; width: 100%;"> Imprimir ticket
                  </a>
                  </ng-template>
                  <ng-template #popCancelar ><b>Opciones</b></ng-template>
                  <button type="button" class="btn btn-link" *ngIf="!l.pagado" placement="left" [ngbPopover]="popCancelarPedido" 
                    [popoverTitle]="popCancelar">
                    <i class="fas fa-ellipsis-v"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>          
        </table>
        <div style="width:max-content; color: #ff8073;">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
      <div *ngIf='listaVentas.length == 0'>
        No existen registros.
      </div>
    </div>
  </div>


  <ng-template #deleteModal let-modal>
    <form (ngSubmit)="deleteVenta()">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModalLabel">Cancelar compra</h5>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            ¿<strong><label>{{ updateVenta.usuario.email }}</label></strong> estás seguro de eliminar el pedido
            con total: <strong><label>{{ updateVenta.total }}</label></strong>
            y folio: <strong><label>{{ updateVenta.id }}</label></strong>?
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal(deleteModal)">Cancelar</button>
        <button class="btn btn-danger mr-2">Eliminar</button>
      </div>
    </form>
  </ng-template>

  <ng-template #seeModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="deleteModalLabel">Ticket de compra
        #<strong><label>{{ updateVenta.id }}</label></strong> </h5>
    </div>
    <div class="modal-body">
      <div class="card">
        <div class="card-body mb-3">


          <div class="row ml-2 mr-2">
            <div class="mr-auto">
              <strong><label>{{ updateVenta.usuario.email }}</label></strong><br />
            </div>
            <div>
              <strong><label>{{ updateVenta.fecha_registro  | date:'dd/MMM/yyy' }}</label></strong><br />
            </div>
          </div>

          <!-- <div class="form-check">
            <input type="checkbox" class="form-check-input" id="final" [(ngModel)]="updateVenta.venta_finalizada"
              disabled>
            <label class="form-check-label" for="final">Finalizada</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="final" [(ngModel)]="updateVenta.pagado" disabled>
            <label class="form-check-label" for="final">Pagada</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="final" [(ngModel)]="updateVenta.entregado" disabled>
            <label class="form-check-label" for="final">Entregada</label>
          </div> -->
          <hr>
          <div *ngFor="let p of productosVenta">
            <div class="d-flex flex-row">
              <div class="mr-auto ml-2 d-flex align-items-center">
                <img class="img-fluid mr-2" style="width: 50px; height: 50px;" [src]="DJANGO_SERVER+ p.producto.imagen_mini" alt="" />
                <a [routerLink]="['/blog/'+p.producto.id]" (click)="modal.dismiss('Producto')"> {{ p.producto.nombre}} </a> 
              </div>
              <div  class="d-flex align-items-center" >
                <strong><label>${{ p.producto.precio }} x <strong>{{ p.cantidad}}</strong></label></strong>
              </div>
            </div>
            <hr>
          </div>
          
          <div>
            <p>
              Costo envio: $ {{updateVenta.costo_envio}}
            </p>
          </div>
          <hr>
          <div class="text-success" *ngIf='updateVenta.pagado' style="right: 10px; position: absolute;">
            <h3>Total: $ <label>{{ updateVenta.total }}</label><br /></h3>
          </div>
          <div class="text-danger" *ngIf='!updateVenta.pagado' style="right: 10px; position: absolute;">
            <h3>Total: $ <label>{{ updateVenta.total }}</label><br /></h3>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closeModal(seeModal)">Aceptar</button>
    </div>
  </ng-template>


</div>

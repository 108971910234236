<div class="container">

  <h2 class="titulo-plumon mb-5" style="margin-top: 130px;">Aviso de privacidad</h2>
  <div class="row"  class="mt-1 mb-1" style="text-align: center; background-color: white; color: black; border-radius: 5px;">
   <div class="mb-2">
    La información que tenemos es principalmente la que tú nos das directamente y la usamos sólo para brindarte un mejor servicio. 
    Drink Art es un negocio para llevar arte, no regalaremos ni compartiremos tus datos con nadie.
   </div>
    <ul class="text-left" style="font-style: italic;">
      <h4>
        ¿Qué información recopilamos?
      </h4> <br>
      <li>A través del proceso de compra se solicitarán ciertos datos, tales como: dirección de entrega de los productos, nombre completo, dirección de correo, teléfono, entre otros; los cuales se entenderán suscritos al momento de llevar a cabo la compra. Dicha información deberá ser veraz y completa, por lo que responderás en todo momento por la veracidad de esta. Y en ningún caso será responsable de estos.
        Usted manifiesta su consentimiento para el tratamiento de sus datos personales de acuerdo con los términos y condiciones de este Aviso de Privacidad.
      </li>
     </ul> 
   <ul class="text-left" style="font-style: italic;">¿Para qué utilizamos tu información?<br>
     <li>
       <p>
        Los datos personales que se solicitan son destinados para fines de contacto, comunicación y envío de sus productos, en ningún momento son utilizados para transmisión o difusión de diversas plataformas digitales ni para fines estadísticos.      
       </p>
       <p>
        En la recopilación y tratamiento de datos personales cumplimos con todos los principios que hace referencia la Ley Federal de Protección de Datos Personales en Posesión de Particulares y su Reglamento, es decir con los principios de licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad.
       </p>
     </li>
   </ul> 
   <ul class="text-left" style="font-style: italic;"><h4>
    ¿Con quién compartimos tu información?
   </h4> <br>
   <li>
     <p>
      Los datos proporcionados a Drink Art son utilizados únicamente por Drink Art para los fines ya enunciados y no son compartidos con ningún tercero. En tal sentido, Drink Art tendrá el manejo y administración de los datos personales que se recaben.
     </p>
     <p>
      Los datos personales que usted proporciona pueden compilarse y fijarse en una base de datos propiedad exclusiva de Drink Art y en ningún momento se le solicitarán datos personales sensibles.
     </p>
     <p>
      Drink Art únicamente podrá difundir su información personal a solicitud expresa de alguna autoridad competente en caso de que exista alguna situación de emergencia o de estado de salud grave; se deba cumplir alguna obligación derivada de una relación jurídica entre usted y Drink Art o bien, que la información figure en fuentes de acceso público.
     </p>
   </li>
  
   </ul> 
   <ul class="text-left" style="font-style: italic;">
    <h4>
      Obligaciones del usuario
    </h4> <br>
   <li>Estás obligado a garantizar la veracidad y exactitud de los datos que nos proporciones y llevar a cabo cuantas actualizaciones de estos fueran necesarias. En todo caso, Tú serás el único responsable de las manifestaciones falsas o inexactas que realices y de los perjuicios que nos causen a nosotros, a otros usuarios o terceros por la información que facilites.
   <li>Abstenerte de realizar cualquier tipo de acción que pudiera inutilizar, sobrecargar o dañar sistemas, equipos o servicios del Sitio, directa o indirectamente. 
   <li>
    En general, no puedes utilizar ningún tipo de aparato, software o rutina, existente o por inventar, que interfiera o entorpezca con el funcionamiento adecuado del Sitio y te obligas a no utilizar técnicas de ingeniería inversa, descompilación, o desensamblaje, ni a modificar, traducir el Sitio, toda vez que él mismo goza de la protección que las leyes y tratados de propiedad industrial e intelectual establecen. 
    <li>
      Queda estrictamente prohibido hacer cualquier tipo de publicación con fines comerciales en el entendido de que Drink Art presta un Servicio mas no es una página de clasificados.</li>   
   <br>
   <h4>
    Aceptación de términos
   </h4>
   <li>Si utilizas los servicios de este sitio, significa que has leído, entendido y aceptado los términos antes expuestos. Si no estás de acuerdo con ellos, no deberás proporcionar ninguna información personal.
   <li>Cuando utilizas nuestros servicios eres responsable de mantener la confidencialidad de los datos de tu cuenta y tu contraseña, así como de restringir el acceso a tu computadora, y asumes la responsabilidad de cualesquiera actividades realizadas desde tu cuenta o utilizando tu contraseña.
   <li>Este aviso puede tener cambios en el futuro; de ser así se le avisará oportunamente en la sección de Aviso de Privacidad de este sitio web.
   </ul> 
  </div> 
  
</div>